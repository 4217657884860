import {reactive} from 'vue'

export default reactive({
    layout: {
        game_remember_it:{
            sec: 'сек',
            annotation: `
                Вам будут показаны карточки, вам необходимо указать - знаете ли вы это слово или нет. Если нет
    - слово отправится на повторение (5 раз), если да - то считается, что слово повторилось 5 раз.
    <br/>
    <i>По умолчанию карточки отображаются из вашего словаря, выставленного в настройках по умолчанию в случайном порядке.
      Вы можете задать нужный вам словарь и порядок выборке. Например слова могут выводится по дате последнего повторения.
      Также для профилактики, вы можете указать, что следует повторять изученые слова.
      Отображение карточек можно выбрать как списком, так и по одной карточке. А, также можно отображать только оригинальное слово,
      а на остальные слова выставить задержку или перевод только после нажатия кнопки.
      В конце игры вам отобразиться статистика слов.
      Если готовы начинать, нажмите старт. Если необходимо изменить настрйоки, нажмите настройки
    </i>`,
            options_form: {
                vocabulary: {
                        label: 'Словарь'
                    },
                counter: {
                    label: 'Количество карточек'
                },
                order: {
                    label: 'Порядок',
                    options: {
                        alf: {
                            label: 'Алфавитный'
                        },
                        rand: {
                            label: 'Случайный'
                        },
                        date: {
                            label: 'Дата последнего повторения'
                        },
                        min: {
                            label: 'Минимальное количество повторов'
                        }
                    }
                },
                retry: {
                    label: 'Включить изученные'
                },
                counter_sec: {
                    label: 'Количество секунд'
                }
            },
            card: {
                    buttons:{
                        edit:{
                            label: 'Редактировать'
                        },
                        del:{
                            label: 'Удалить'
                        },
                        remember:{
                            label: 'Помню'
                        },
                        forgot:{
                            label: 'Забыл'
                        }
                    }
            },
            finish: {
                result_h1: {
                    5: 'Отлично',
                    4: 'Хорошо',
                    3: 'Неплохо',
                    2: 'Плохо',
                    1: 'Ужасно'
                },
                result_description: {
                    5: 'Вы знаете все слова! Браво!',
                    4: 'Вы знаете почти все! ',
                    3: 'Вы знаете, боьшую часть слов, но не мешало бы некоторые слова повторить.',
                    2: 'Вы кое что знаете, но лучше бы повторить все',
                    1: 'То что вы что-то знаете - чистая случайность. рекомендуем повторить вам все слова'
                },
                result_button:{
                    label: 'Результаты'
                },
                retry_button:{
                    label: 'Повторить'
                },
                max_time_label:  'Maксимальное время',
                mid_time_label:  'Среднее время',
                min_time_label:  'Минимальное время',
                last_time_is: 'В прошлый раз оно составляло',
                present_label: 'Всего правильных слов',
                this_words_need_try_label: 'Следующие слова не мешало бы повторить',
                result_title: 'Результаты',
                are_you_sure: 'Подверждение удаления',
                do_you_want: 'Вы действительно хотите удалить эту карточку? Восстановление невозможно!',
                del_button: 'Удалить',
                cancel_button: 'Отмена',
                table:{
                    note1: {
                        label: 'Слово'
                    },
                    note2: {
                        label: 'Перевод'
                    },
                    note3: {
                        label: 'Пояснение'
                    },
                    status: {
                        label: 'Статус'
                    },
                    progress: {
                        label: 'Прогресс'
                    },
                    action:{
                        label: 'Действия'
                    },
                    success_button: 'Успех',
                    fail_button: 'Провалено',
                    points_label: 'очков'
                },
            },
            start: {
              label: 'Старт',
            },
            options: {
              label: 'Настройки',
            }
        },
        vocabularies: {
          public_vocabularies:
            {
              title: 'Публичные словари',
              number_of_words: 'Количество слов',
              author: 'Автор',
              preview_button: 'Просмотр'
            },
          my_vocabularies:
            {
              title: 'Мои словари',
              number_of_words: 'Количество слов',
              edit_button: 'Редактировать',
              add_button: {
                label: 'Новый словарь'
              },
            },
          new_vocabulary: {
            title: 'Новый словарь',
            form:{
              title: {
                label: 'Название',
              },
              description: {
                label: 'Описание',
              },
              image: {
                label: 'Изображение',
                hint: 'Перетащите сюда картинку или выберите. Изображение должно быть размером 48x48 пикселей'
              },
              public:{
                label: 'Публичный'
              },
              default_vocabulary: {
                label: 'По-умолчанию'
              },
              create_button: {
                label: 'Создать'
              },
              cancel_button: {
                label: 'Отмена'
              }
            },
            success_saved: {
              label: 'Успех',
              description: 'Словарь успешно сохранен'
            }
          },
          edit_vocabulary: {
            title: 'Редактировать словарь',
            form:{
              title: {
                label: 'Название',
              },
              description: {
                label: 'Описание',
              },
              image: {
                label: 'Изображение',
                hint: 'Перетащите сюда картинку или выберите. Изображение должно быть размером 48x48 пикселей'
              },
              public:{
                label: 'Публичный'
              },
              default_vocabulary: {
                label: 'По-умолчанию'
              },
              update_button : {
                label: 'Обновить'
              },
              cancel_button: {
                label: 'Отмена'
              },

            },
            success_saved: {
              label: 'Успех',
              description: 'Словарь успешно сохранен'
            }
          },
          cards: {
            table:{
              word_header: 'Слово',
              translate_header: 'Перевод',
              note_header: 'Заметка',
              sum_points_for_retry_header: 'Количество очков для повтора',
              real_points_header: 'Пройденное количество очков',
              loading: 'Loading...',
              note1: {
                label: 'Слово'
              },
              note2: {
                label: 'Перевод'
              },
              note3: {
                label: 'Пояснение'
              },
              status: {
                label: 'Статус',
                no_studied: 'Не изучено',
                studied: 'Изучено'
              },
              progress: {
                label: 'Прогресс'
              },
              action:{
                label: 'Действия'
              },
              last_view_date: {
                label: 'Последний просмотр'
              },
              success_button: 'Успех',
              fail_button: 'Провалено',
              points_label: 'очков',
              search: {
                placeholder: 'Поиск...'
              },
              import_button: 'Импорт',
              import_button_disabled_tooltip: 'Импорт невозможен! Пожалуйста пометтье слова галкой, которые нужно импортировать',
              import_success_description: 'Импорт успешно выполнен',

            },
            studied_button: 'Изучено',
            retry_button: 'Повтор',
            delete_button: 'Удалить',
            edit_word_title: 'Редактировать слово',
            author_title: 'Автор'
          },
      },
        settings:{
          languages: {
            ru: {title: 'Русский'},
            en: {title: 'Английский'},
            it: {title: 'Итальянский'},
            es: {title: 'Испанский'},
            fr: {title: 'Французкий'},
            de: {title: 'Немецкий'}
          },
          options:
            {
              title: 'Общие настройки',
              language: {
                label: 'Язык'
              },
              key:{
                label: 'Ключ'
              },
              end_date:{
                label: 'Дата окончания'
              },
              limitations:{
                label: 'Ограничения'
              },
              default_vocabulary: {
                label: 'Словарь по умолчанию'
              },
              extend_button: 'Продлить'
            },
          profile:{
            title: 'Профиль',
            name: {
              label: 'Имя'
            }
          },
          save_button: 'Сохранить',
          success_saved: {
            label: 'Успех',
            description: 'Настройки успешно сохранены'
          }

        },
        pay:{
          title: 'Ваша помощь',
          description: 'Вы можете помочь нам. Для этого вам необходимо оплатить месячный абонимент на месяц. Или же вы можете оплатить на год вперед. Если вы хотите просто помочь в развитии проекта - нажмите кнопку пожертвовать. Все поступающие деньги идут на развитие проекта. Все платежи обрабатываются в течении суток, поэтому если вы оплатили, требуется время на обработку. Обычно это не более 4 часов',
          month : {
            title: '1 месяц',
            description: ' 1 месяц с возможностью работать с словарными карточками. Доступ к часам, веб-приложению и плагинам для браузеров.'
          },
          year : {
            title: '1 год',
            description: ' 1 год с возможностью работать с словарными карточками. Доступ к часам, веб-приложению и плагинам для браузеров. В дальнейшем функционалбудет расширен, но для пользователей с годовой подпиской - все фичи будут доступны.'
          },
          donate: {
            title: 'Донат',
            description: 'Вы можете просто поддержать наш проект. Все пожертвования идут не развитие! При большом пожервтовании можем сделать любую индивидуальную фичу! '
          },
          paypal_tax: 'комиссия paypal',
          pay_button: 'Оплатить'
        },
        login: {
          user: {
            label: 'Пользователь',
            placeholder: 'Email'
          },
          password: {
            label: 'Пароль'
          },
          login_button: 'Вход',
          forgot_password_button: 'Забыли пароль',
          old_password: 'старая версия',
          login_error: 'Ошибка авторизации!'
        }

    },
    menu:{
      vocabularies: {
        label: 'Словари'
      },
      games: {
        label: 'Игры'
      },
      remember_it: {
        label: 'Запомни это!'
      },
      settings: {
        label: 'Настройки'
      },

    }
});
