<template>
  <table class="mt-10 border-collapse w-full">
    <thead>
    <tr>
      <th class="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">{{currentPage.finish.table.note1.label}}</th>
      <th class="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">{{currentPage.finish.table.note2.label}}</th>
      <th class="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">{{currentPage.finish.table.note3.label}}</th>
      <th class="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">{{currentPage.finish.table.status.label}}</th>
      <th class="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">{{currentPage.finish.table.progress.label}}</th>
      <th class="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">{{currentPage.finish.table.action.label}}</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for = "(card, index) in store.finish.result.cards" :key = card.id  class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
      <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
        <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">{{currentPage.finish.table.note1.label}}</span>
        {{card.note1}}
      </td>
      <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
        <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">{{currentPage.finish.table.note2.label}}</span>
        {{card.note2}}
      </td>
      <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
        <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">{{currentPage.finish.table.note3.label}}</span>
        {{card.note3}}
      </td>
      <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
        <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">{{currentPage.finish.table.status.label}}</span>


        <span v-if = "store.data.cards[index].result == true" class="rounded bg-green-400 py-1 px-3 text-xs font-bold">{{currentPage.finish.table.success_button}}</span>
        <span v-else class="rounded bg-red-400 py-1 px-3 text-xs font-bold">{{currentPage.finish.table.fail_button}}</span>
      </td>
      <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
        <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">{{currentPage.finish.table.progress.label}}</span>

        <div v-if = "store.data.cards[index].result == true"   class="badge badge-success  bg-theme-9 m-auto rounded-lg w-1/2 text-white">+5 {{currentPage.finish.table.points_label}}</div>
        <div v-else  class="bg-theme-6  m-auto rounded-lg w-1/2 text-white">-5 {{currentPage.finish.table.points_label}}</div>

        <progress class="progress" v-bind:value="card.real_count" :max="card.max_count"></progress>

      </td>
      <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static ">
        <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">{{currentPage.finish.table.action.label}}</span>
        <a href="#" class="btn btn-dark m-2">
          <Edit2Icon class="w-4 h-4 mr-1" />
        </a>
      </td>
    </tr>
    </tbody>
  </table>
  <div
    id="delete-confirmation-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="p-5 text-center">
            <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
            <div class="text-3xl mt-5">{{currentPage.finish.are_you_sure}}</div>
            <div class="text-gray-600 mt-2">
              {{currentPage.finish.do_you_want}}
            </div>
          </div>
          <div class="px-5 pb-8 text-center">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-24 mr-1"
            >
              {{currentPage.finish.cancel_button}}
            </button>
            <button type="button" class="btn btn-danger w-24" @click = "delCard">{{currentPage.finish.del_button}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lang from "../mixins.js";
import Api from "@/api";

const api = new Api();
export default {
name: "ftable",
  mixins: [lang],
  data()
  {
    return {
      ind: null
    }
  },
  methods:
    {
      del(ind)
      {
          this.ind = parseInt(ind);
      },
      async delCard()
      {
        console.log('try delCard');
        var id  = this.store.data.cards[this.ind].id;
//
        var result = await api.post('card/delete/'+id, {auth: true})


/*        this.$delete(this.store.finish.result.cards, this.ind)
        this.$delete(this.store.data.cards, this.ind)*/


        this.store.data.cards = JSON.parse(JSON.stringify(this.store.data.cards.filter(item => { return item.id != id})));
        this.store.finish.result.cards = JSON.parse(JSON.stringify(this.data.store.finish.result.cards.filter(item => { return item.id != id})));



      }
    }
}
</script>

<style>

@media (min-width: 640px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  border-bottom: 0;
}

th:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, .1);
}
</style>
