<template>
  <div>
    <div v-if = "$route.params.id">
      <router-view></router-view>
    </div>
    <div v-else>
    <div>
      <add-vocabulary v-if = "store.ui.views.vocabularies.add_form" >
      </add-vocabulary>
      <edit-vocabulary v-if = "store.ui.views.vocabularies.edit_form" class="modal-content"  v-model:id = "store.ui.views.vocabularies.currentId" >
      </edit-vocabulary>
    </div>
    <div class="intro-y flex items-center mt-8">
    <h2 class="text-lg font-medium mr-auto"><h2 class="text-lg font-medium mr-auto">{{currentPage.my_vocabularies.title}}</h2></h2>
    <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
      <a
        class="btn btn-primary shadow-md mr-2"
        @click = "newVocabulary"
      >{{currentPage.my_vocabularies.add_button.label}}</a>
    </div>
    </div>

    <div class="flex flex-wrap w-full">
      <my-vocabulary v-for ="item in store.data.my_vocabularies" v-model:id = "item.id" :key = "item.id"></my-vocabulary>
    </div>

    <div class="intro-y flex items-center mt-8"><h2 class="text-lg font-medium mr-auto">{{currentPage.public_vocabularies.title}}</h2></div>
    <div class="flex flex-wrap w-full">
      <public-vocabulary v-for ="item in store.data.public_vocabularies" v-model:id = "item.id" :key = "item.id"></public-vocabulary>
    </div>
    </div>

  </div>
</template>

<script>

import Api from "@/api.js";
import PublicVocabulary from "@/views/vocabularies/PublicVocabulary";
import lang from "@/views/vocabularies/lang";
import MyVocabulary from "@/views/vocabularies/MyVocabulary";
import {ref} from "vue";
import AddVocabulary from "@/views/vocabularies/AddVocabulary";
import EditVocabulary from "@/views/vocabularies/EditVocabulary";
import PublicCards from "@/views/vocabularies/PublicCards";


const api = new Api();
export default {
  name: "Main",
  mixins: [lang],
  setup()
  {
    const editorData = ref('');

    return {
      editorData
    }

  },
  data()
  {
    return {
      new_vocabulary: false
    }
  },
  components: {
    PublicVocabulary, MyVocabulary, AddVocabulary, EditVocabulary, PublicCards
  },
  async mounted()
  {
    this.store.data.my_vocabularies = await api.get('vocabulary/my', {auth: true});

    this.store.data.public_vocabularies = await api.get('vocabulary/public', {auth: true});

  },
  methods:{
    newVocabulary()
    {
      this.store.ui.views.vocabularies.edit_form = false;
      this.store.ui.views.vocabularies.add_form = !this.store.ui.views.vocabularies.add_form;
    }
  }
}
</script>

<style scoped>

</style>
