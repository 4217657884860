import {reactive} from 'vue'

export default reactive({
    layout: {
        game_remember_it:{
            sec: 'Sek',
            annotation: `
                Ihnen werden Karten gezeigt, Sie müssen angeben, ob Sie dieses Wort kennen oder nicht. Wenn nicht
     - das Wort wird wiederholt (5 Mal), wenn ja - dann wird davon ausgegangen, dass das Wort 5 Mal wiederholt wurde.
    <br/>
    <i>Standardmäßig werden Karten aus Ihrem in den Standardeinstellungen festgelegten Wörterbuch in zufälliger Reihenfolge angezeigt.
      Sie können das gewünschte Vokabular und die Reihenfolge der Auswahl festlegen. Beispielsweise können Wörter nach dem Datum der letzten Wiederholung angezeigt werden.
      Auch zur Vorbeugung können Sie festlegen, dass Sie die gelernten Wörter wiederholen sollen.
      Die Anzeige der Karten kann als Liste oder einzeln ausgewählt werden. Und es ist auch möglich, nur das ursprüngliche Wort anzuzeigen,
      und stellen Sie die Verzögerung oder Übersetzung für den Rest der Wörter erst ein, nachdem Sie die Taste gedrückt haben.
      Am Ende des Spiels sehen Sie die Statistiken der Wörter.
      Wenn Sie startbereit sind, drücken Sie Start. Wenn Sie Einstellungen ändern müssen, klicken Sie auf Einstellungen
    </i>`,
            options_form: {
                vocabulary: {
                        label: 'Vokabular'
                    },
                counter: {
                    label: 'Anzahl der Karten'
                },
                order: {
                    label: 'Reihenfolge',
                    options: {
                        alf: {
                            label: 'Alphabetisch'
                        },
                        rand: {
                            label: 'Zufällig'
                        },
                        date: {
                            label: 'Datum der letzten Wiederholung'
                        },
                        min: {
                            label: 'Mindestanzahl an Wiederholungen'
                        }
                    }
                },
                retry: {
                    label: 'Gelernte Wörter einbeziehen'
                },
                counter_sec: {
                    label: 'Anzahl Sekunden'
                }
            },
            card: {
                    buttons:{
                        edit:{
                            label: 'Bearbeiten'
                        },
                        del:{
                            label: 'Löschen'
                        },
                        remember:{
                            label: 'Ich erinnere mich'
                        },
                        forgot:{
                            label: 'Ich habe vergessen'
                        }
                    }
            },
            finish: {
                result_h1: {
                    5: 'Sehr gut',
                    4: 'Gut',
                    3: 'Ganz nett',
                    2: 'Schwach',
                    1: 'Schrecklich'
                },
                result_description: {
                    5: 'Sie kennen alle Wörter! Bravo!',
                    4: 'Sie kennen fast alles! ',
                    3: 'Вы знаете, боьшую часть слов, но не мешало бы некоторые слова повторить.',
                    2: 'Sie kennen die meisten Wörter, aber es würde nicht schaden, einige Wörter zu wiederholen.',
                    1: 'Dass man Sie etwas kennen, ist reiner Zufall. Wir empfehlen Ihnen, alle Wörter zu wiederholen'
                },
                result_button:{
                    label: 'Ergebnisse'
                },
                retry_button:{
                    label: 'Wiederholen'
                },
                max_time_label:  'Maximale Zeit',
                mid_time_label:  'Durchschnittliche Zeit',
                min_time_label:  'Mindestzeit',
                last_time_is: 'Letztes Mal war es',
                present_label: 'Total richtige Worte',
                this_words_need_try_label: 'Es würde nicht schaden, die folgenden Worte zu wiederholen',
                result_title: 'Ergebnisse',
                are_you_sure: 'Löschbestätigung',
                do_you_want: 'Möchten Sie diese Karte wirklich löschen? Eine Wiederherstellung ist nicht möglich!',
                del_button: 'Löschen',
                cancel_button: 'Abbrechen',
                table:{
                    note1: {
                        label: 'Wort'
                    },
                    note2: {
                        label: 'Übersetzung'
                    },
                    note3: {
                        label: 'Erläuterung'
                    },
                    status: {
                        label: 'Status'
                    },
                    progress: {
                        label: 'Progress'
                    },
                    action:{
                        label: 'Aktionen'
                    },
                    success_button: 'Erfolg',
                    fail_button: 'Durchfallen',
                    points_label: 'Punkte'
                },
            },
            start: {
              label: 'Start',
            },
            options: {
              label: 'Einstellungen',
            }
        },
        vocabularies: {
          public_vocabularies:
            {
              title: 'Öffentliche Vokabulare',
              number_of_words: 'Anzahl der Wörter',
              author: 'Autor',
              preview_button: 'Durchsehen'
            },
          my_vocabularies:
            {
              title: 'Maine Vokabulare',
              number_of_words: 'Anzahl der Wörter',
              edit_button: 'Bearbeiten',
              add_button: {
                label: 'Neues Vokabular'
              }
            },
          new_vocabulary: {
            title: 'Neues Vokabular',
            form:{
              title: {
                label: 'Titel',
              },
              description: {
                label: 'Beschreibung',
              },
              image: {
                label: 'Bild',
                hint: 'Ziehen Sie ein Bild hierher oder wählen Sie es aus. Das Bild muss 48 x 48 Pixel groß sein'
              },
              public:{
                label: 'Öffentliche'
              },
              default_vocabulary: {
                label: 'als Vorgabe'
              },
              create_button: {
                label: 'Schaffen'
              },
              cancel_button: {
                label: 'Abbrechnen'
              }
            },
            success_saved: {
              label: 'Erfolg',
              description: 'Vokabular erfolgreich gespeichert'
            }
          },
          edit_vocabulary: {
            title: 'Vokabular bearbeiten',
            form:{
              title: {
                label: 'Titel',
              },
              description: {
                label: 'Beschreibung',
              },
              image: {
                label: 'Bild',
                hint: 'Ziehen Sie ein Bild hierher oder wählen Sie es aus. Das Bild muss 48 x 48 Pixel groß sein'
              },
              public:{
                label: 'Öffentliche'
              },
              default_vocabulary: {
                label: 'als Vorgabe'
              },
              update_button : {
                label: 'Aktualisieren'
              },
              cancel_button: {
                label: 'Abbrechnen'
              },

            },
            success_saved: {
              label: 'Erfolg',
              description: 'Vokabular erfolgreich gespeichert'
            }
          },
          cards: {
            table:{
              word_header: 'Wort',
              translate_header: 'Übersetzung',
              note_header: 'Notiz',
              sum_points_for_retry_header: 'Anzahl der zu wiederholenden Punkte',
              real_points_header: 'Punkte bestanden',
              loading: 'Loading...',
              note1: {
                label: 'Wort'
              },
              note2: {
                label: 'Übersetzung'
              },
              note3: {
                label: 'Erläuterung'
              },
              status: {
                label: 'Status',
                no_studied: 'Nicht gelernt',
                studied: 'Gelernt'
              },
              progress: {
                label: 'Progress'
              },
              action:{
                label: 'Aktionen'
              },
              last_view_date: {
                label: 'Zuletzt angesehen'
              },
              success_button: 'Erfolg',
              fail_button: 'Gescheitert',
              points_label: 'Punkte',
              search: {
                placeholder: 'Suche...'
              },
              import_button: 'Import',
              import_button_disabled_tooltip: 'Import nicht möglich! Bitte kreuzen Sie die zu importierenden Wörter an',
              import_success_description: 'Import erfolgreich abgeschlossen',

            },
            studied_button: 'Gelernt',
            retry_button: 'Wiederholen',
            delete_button: 'Löschen',
            edit_word_title: 'Wort bearbeiten',
            author_title: 'Autor'
          },
      },
        settings:{
          languages: {
            ru: {title: 'Russisch'},
            en: {title: 'Englisch'},
            it: {title: 'Italienisch'},
            es: {title: 'Spanisch'},
            fr: {title: 'Französisch'},
            de: {title: 'Deutsch'}
          },
          options:
            {
              title: 'Allgemeine Einstellungen',
              language: {
                label: 'Sprache'
              },
              key:{
                label: 'Schlüssel'
              },
              end_date:{
                label: 'Enddatum'
              },
              limitations:{
                label: 'Beschränkungen'
              },
              default_vocabulary: {
                label: 'Vokabular als Vorgabe'
              },
              extend_button: 'Verlängern'
            },
          profile:{
            title: 'Profil',
            name: {
              label: 'Name'
            }
          },
          save_button: 'Speichern',
          success_saved: {
            label: 'Erfolg',
            description: 'Einstellungen erfolgreich gespeichert'
          }

        },
        pay:{
          title: 'Ihre Hilfe',
          description: 'Sie können uns helfen. Dazu müssen Sie einen Monat lang ein monatliches Abonnement bezahlen. Oder Sie zahlen ein Jahr im Voraus. Wenn Sie nur bei der Entwicklung des Projekts helfen möchten, klicken Sie auf die Schaltfläche „Spenden“. Alle eingehenden Gelder fließen in die Entwicklung des Projekts. Alle Zahlungen werden innerhalb von 24 Stunden verarbeitet. Wenn Sie also bezahlt haben, dauert die Bearbeitung einige Zeit. Normalerweise sind es nicht mehr als 4 Stunden',
          month : {
            title: '1 Monat',
            description: '1 Monat mit der Möglichkeit, mit Vokabelkarten zu arbeiten. Zugriff auf Watch-, Web-App- und Browser-Plugins.'
          },
          year : {
            title: '1 Jahr',
            description: ' 1 Jahr mit der Fähigkeit, mit Vokabelkarten zu arbeiten. Zugriff auf Watch-, Web-App- und Browser-Plugins. In Zukunft wird die Funktionalität erweitert, aber für Benutzer mit einem Jahresabonnement werden alle Funktionen verfügbar sein.'
          },
          donate: {
            title: 'Donat',
            description: 'Sie können unser Projekt einfach unterstützen. Alle Spenden gehen nicht in die Entwicklung! Mit einer großen Spende können wir jedes individuelle Feature machen! '
          },
          paypal_tax: 'Kommission paypal',
          pay_button: 'Bezahlen'
        },
        login: {
          user: {
            label: 'Benutzer',
            placeholder: 'Email'
          },
          password: {
            label: 'Passwort'
          },
          login_button: 'Einloggen',
          forgot_password_button: 'Passwort vergessen',
          old_password: 'alte Version',
          login_error: 'Autorisierungsfehler!'
        }

    },
    menu:{
      vocabularies: {
        label: 'Vikabulare'
      },
      games: {
        label: 'Spielen'
      },
      remember_it: {
        label: 'Erinnere dich dran!'
      },
      settings: {
        label: 'Einstellungen'
      },

    }
});
