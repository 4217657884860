<template>
  <div>
    <div v-if ="store.ui.page == 'start'">
      <start></start>
    </div>
    <div v-else-if = "store.ui.page == 'process'">
      <game></game>
    </div>
    <div v-else>
      <finish></finish>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import Start from "./start/index.vue";
import Game from "./game/index.vue";
import Finish from "./finish/index.vue";

export default defineComponent({
  components: {
    Start,
    Game,
    Finish
  },
})
</script>
