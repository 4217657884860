import {reactive} from 'vue'

export default reactive({
    layout: {
        game_remember_it:{
            sec: 'seg',
            annotation: `
                Se le mostrarán las tarjetas, debe especificar si conoce esta palabra o no. Si no
      - la palabra se repetirá (5 veces), si es así, se considera que la palabra se repitió 5 veces.
    <br/>
    <i>De forma predeterminada, las tarjetas se muestran desde su diccionario establecido en la configuración predeterminada en orden aleatorio.
      Puede especificar el diccionario que desee y el orden de la muestra. Por ejemplo, las palabras pueden ser mostradas por la fecha de la última repetición.
      También para la prevención, puede indicar que debe repetir las palabras aprendidas.
      La visualización de las tarjetas se puede seleccionar como una lista o una sola tarjeta. También puede mostrar sólo la palabra original,
      y en el resto de las palabras para establecer un retraso o traducción sólo después de pulsar el botón.
      Al final del juego se muestran las estadísticas de palabras.
      Si está listo para comenzar, haga clic en Inicio. Si desea cambiar la configuración, haga clic en configuración
    </i>`,
            options_form: {
                vocabulary: {
                        label: 'Diccionario'
                    },
                counter: {
                    label: 'Número de tarjetas'
                },
                order: {
                    label: 'Orden',
                    options: {
                        alf: {
                            label: 'Alfabético'
                        },
                        rand: {
                            label: 'Aleatorio'
                        },
                        date: {
                            label: 'Fecha de la última repetición'
                        },
                        min: {
                            label: 'Número mínimo de repeticiones'
                        }
                    }
                },
                retry: {
                    label: 'Incluir lo aprendido'
                },
                counter_sec: {
                    label: 'Número de segundos'
                }
            },
            card: {
                    buttons:{
                        edit:{
                            label: 'Editar'
                        },
                        del:{
                            label: 'Eliminar'
                        },
                        remember:{
                            label: 'Recuerdo'
                        },
                        forgot:{
                            label: 'Olvidé'
                        }
                    }
            },
            finish: {
                result_h1: {
                    5: 'Genial',
                    4: 'Bien',
                    3: 'No está mal',
                    2: 'Malo',
                    1: 'Horrible'
                },
                result_description: {
                    5: '¡Conoce todas las palabras! ¡Bravo!',
                    4: '¡Sabe casi todo! ',
                    3: 'Usted sabe que la parte temida de las palabras, pero no estaría de más repetir algunas palabras.',
                    2: 'Sabe algo, pero es mejor repetirlo todo',
                    1: 'El hecho de que sepa algo es pura casualidad. Le recomendamos que repita todas las palabras'
                },
                result_button:{
                    label: 'Resultados'
                },
                retry_button:{
                    label: 'Repetir'
                },
                max_time_label: 'Tiempo máximo',
                mid_time_label: 'Tiempo medio',
                min_time_label: 'Tiempo mínimo',
                last_time_is: 'La última vez fue',
                present_label: 'Total de palabras correctas',
                this_words_need_try_label: 'Las siguientes palabras no estarían de más repetir',
                result_title: 'Resultados',
                are_you_sure: 'Confirmación de eliminación',
                do_you_want: '¿De verdad quiere eliminar esta tarjeta? ¡La recuperación es imposible!',
                del_button: 'Eliminar',
                cancel_button: 'Cancelar',
                table:{
                    note1: {
                        label: 'Palabra'
                    },
                    note2: {
                        label: 'Traducción'
                    },
                    note3: {
                        label: 'Explicación'
                    },
                    status: {
                        label: 'Estado'
                    },
                    progress: {
                        label: 'Progreso'
                    },
                    action:{
                        label: 'Acciones'
                    },
                    success_button: 'Éxito',
                    fail_button: 'Fracasado',
                    points_label: 'puntos'
                },
            },
            start: {
              label: 'Comienzo',
            },
            options: {
              label: 'Ajustes',
            }

        },
        vocabularies: {
          public_vocabularies:
            {
              title: 'Diccionarios públicos',
              number_of_words: 'Número de palabras',
              author: 'Autor',
              preview_button: 'Ver'
            },
          my_vocabularies:
            {
              title: 'Mis diccionarios',
              number_of_words: 'Número de palabras',
              edit_button: 'Editar',
              add_button: {
                label: 'Nuevo diccionario'
              }
            },
          new_vocabulary: {
            title: 'Nuevo diccionario',
            form:{
              title: {
                label: 'Título',
              },
              description: {
                label: 'Descripción',
              },
              image: {
                label: 'Imagen',
                hint: 'Arrastre la imagen aquí o seleccione. La imagen debe tener un tamaño de 48x48 píxeles'
              },
              public:{
                label: 'Público'
              },
              default_vocabulary: {
                label: 'Predeterminado'
              },
              create_button: {
                label: 'Crear'
              },
              cancel_button: {
                label: 'Cancelar'
              }
            },
            success_saved: {
              label: 'Éxito',
              description: 'Diccionario guardado con éxito'
            }
          },
          edit_vocabulary: {
            title: 'Editar diccionario',
            form:{
              title: {
                label: 'Título',
              },
              description: {
                label: 'Descripción',
              },
              image: {
                label: 'Imagen',
                hint: 'Arrastre la imagen aquí o seleccione. La imagen debe tener un tamaño de 48x48 píxeles'
              },
              public:{
                label: 'Público'
              },
              default_vocabulary: {
                label: 'Predeterminado'
              },
              update_button : {
                label: 'Actualizar'
              },
              cancel_button: {
                label: 'Cancelar'
              },

              },
              success_saved: {
                label: 'Éxito',
                description: 'Diccionario guardado con éxito'
              }
          },
          cards: {
            table:{
              word_header: 'Palabra',
              translate_header: 'Traducción',
              note_header: 'Nota',
              sum_points_for_retry_header: 'Puntuación de repetición',
              real_points_header: 'Puntos pasados',
              loading: 'Loading...',
              note1: {
                label: 'Palabra'
              },
              note2: {
                label: 'Traducción'
              },
              note3: {
                label: 'Explicación'
              },
              status: {
                label: 'Estado',
                no_studied: 'No aprendido',
                studied: 'Aprendido'
              },
              progress: {
                label: 'Progreso'
              },
              action:{
                label: 'Acciones'
              },
              last_view_date: {
                label: 'Última vista'
              },
              success_button: 'Éxito',
              fail_button: 'Fracasado',
              points_label: 'puntos',
              search: {
                placeholder: 'Buscar...'
              },
              import_button: 'Importar',
              import_button_disabled_tooltip: '¡No se puede importar! Por favor marque las palabras que desea importar',
              import_success_description: 'Importación exitosa',

            },
            studied_button: 'Aprendido',
            retry_button: 'Repetir',
            delete_button: 'Eliminar',
            edit_word_title: 'Editar palabra',
            author_title: 'Autor'
          },
      },
        settings:{
          languages: {
            ru: {title: 'Ruso'},
            en: {title: 'Inglés'},
            it: {title: 'Italiano'},
            es: {title: 'Español'},
            fr: {title: 'Francés'},
            de: {title: 'Alemán'}
          },
          options:
            {
              title: 'Configuración general',
              language: {
                label: 'Idioma'
              },
              key:{
                label: 'Clave'
              },
              end_date:{
                label: 'Fecha de finalización'
              },
              limitations:{
                label: 'Restricciones'
              },
              default_vocabulary: {
                label: 'Diccionario predeterminado'
              },
              extend_button: 'Extender'
            },
          profile:{
            title: 'Perfil',
            name: {
              label: 'Nombre'
            }
          },
          save_button: 'Guardar',
          success_saved: {
            label: 'Éxito',
            description: 'Configuración guardada con éxito'
          }

        },
        pay:{
          title: 'Su ayuda',
          description: 'Usted puede ayudarnos. Para hacer esto, debe pagar un abonado mensual por mes. O puede pagar con un año de anticipación. Si desea simplemente ayudar en el desarrollo del proyecto - haga clic en el botón donar. Todo el dinero que entra se destina al desarrollo del proyecto. Todos los pagos se procesan durante el día, por lo que si ha pagado, se necesita tiempo para procesar. Por lo general, no es más de 4 horas',
          month : {
            title: '1 mes',
            description : '1 mes con la posibilidad de trabajar con tarjetas de vocabulario. Acceda al reloj, la aplicación web y los complementos del navegador.'
          },
          year : {
            title: '1 año',
            description: '1 año con la posibilidad de trabajar con tarjetas de vocabulario. Acceda al reloj, la aplicación web y los complementos del navegador. En el futuro, la funcionalidad se ampliará, pero para los usuarios con una suscripción anual, todas las características estarán disponibles.'
          },
          donate: {
            title: 'Donat',
            description: 'Usted puede simplemente apoyar nuestro proyecto. Todas las donaciones van no desarrollo! ¡Con una gran donación, podemos hacer cualquier característica individual! '
          },
          paypal_tax: 'Comisión de paypal',
          pay_button: 'Pagar'
        },
        login: {
          user: {
            label: 'Usuario',
            placeholder: 'Email'
          },
          password: {
            label: 'Contraseña'
          },
          login_button: 'Iniciar sesión',
          forgot_password_button: 'Olvidé mi contraseña',
          old_password: 'versión antigua',
          login_error: 'Error de autorización!'
        }

    },
    menu:{
      vocabularies: {
        label: 'Diccionarios'
      },
      games: {
        label: 'Juegos'
      },
      remember_it: {
        label: '¡Recuerda esto!'
      },
      settings: {
        label: 'Configuración'
      },

    }
});
