import {reactive} from 'vue'

export default reactive({
    layout: {
        game_remember_it:{
            sec: 'sec',
            annotation: `
                Ti verranno mostrate le carte, devi indicare se conosci o meno questa parola. Se no
      - la parola verrà ripetuta (5 volte), se sì, si considera che la parola è stata ripetuta 5 volte.
    <br/>
    <i>Per impostazione predefinita, le schede vengono visualizzate dal dizionario impostato nelle impostazioni predefinite in ordine casuale.
      È possibile impostare il dizionario desiderato e l'ordine di campionamento. Ad esempio, le parole possono essere visualizzati in base alla data dell'ultima ripetizione.
      Anche per la prevenzione, è possibile specificare che si dovrebbe ripetere le parole apprese.
      È possibile scegliere di visualizzare le schede in un elenco o in una scheda. Anche in grado di visualizzare solo la parola originale,
      e sulle altre parole mettere un ritardo o una traduzione solo dopo aver premuto il pulsante.
      Alla fine del gioco, vengono visualizzate le statistiche delle parole.
      Se si è pronti per iniziare, premere Start. Se si desidera modificare le impostazioni, fare clic su Impostazioni
    </i>`,
            options_form: {
                vocabulary: {
                        label: 'Dizionario'
                    },
                counter: {
                    label: 'Numero di carte'
                },
                order: {
                    label: 'Ordine',
                    options: {
                        alf: {
                            label: 'Alfabetico'
                        },
                        rand: {
                            label: 'Casuale'
                        },
                        date: {
                            label: "Data dell'ultima ricorrenza"
                        },
                        min: {
                            label: 'Numero minimo di ripetizioni'
                        }
                    }
                },
                retry: {
                    label: 'Includi studiato'
                },
                counter_sec: {
                    label: 'Numero di secondi'
                }
            },
            card: {
                    buttons:{
                        edit:{
                            label: 'Modificare'
                        },
                        del:{
                            label: 'Eliminare'
                        },
                        remember:{
                            label: 'Ricordo'
                        },
                        forgot:{
                            label: 'Dimenticato'
                        }
                    }
            },
            finish: {
                result_h1: {
                    5: 'Eccellente',
                    4: 'Buono',
                    3: 'Non male',
                    2: 'Cattivo',
                    1: 'Terribile'
                },
                result_description: {
                    5: 'Sai tutte le parole! Bravo!',
                    4: 'Sai quasi tutto! ',
                    3: 'Sai, la maggior parte delle parole, ma non sarebbe male ripetere alcune parole.',
                    2: 'Sai qualcosa, ma è meglio ripetere tutto',
                    1: 'Il fatto che tu sappia qualcosa è pura coincidenza. Ti consigliamo di ripeterti tutte le parole'
                },
                result_button:{
                    label: 'Risultati'
                },
                retry_button:{
                    label: 'Ripetere'
                },
                max_time_label: 'Tempo massimo',
                mid_time_label: 'Tempo medio',
                min_time_label: 'Tempo minimo',
                last_time_is: "L'ultima volta che ha fatto",
                present_label: 'Parole corrette totali',
                this_words_need_try_label: 'Le seguenti parole non fanno male a ripetere',
                result_title: 'Risultati',
                are_you_sure: 'Conferma eliminazione',
                do_you_want: 'Vuoi davvero eliminare questa scheda? Il recupero è impossibile!',
                del_button: 'Eliminare',
                cancel_button: 'Annullare',
                table:{
                    note1: {
                        label: 'Parola'
                    },
                    note2: {
                        label: 'Traduzione'
                    },
                    note3: {
                        label: 'Spiegazione'
                    },
                    status: {
                        label:' Stato'
                    },
                        progress: {
                    label: 'Progresso'
                    },
                    action:{
                        label: 'Azioni'
                    },
                    success_button: 'Successo',
                    fail_button: 'Fallito',
                    points_label: 'Punti'
                },
            },
            start: {
              label: 'Cominciare',
            },
            options: {
              label: 'Impostazioni',
            }

        },
        vocabularies: {
          public_vocabularies:
            {
              title: 'Dizionari pubblici',
              number_of_words: 'Numero di parole',
              author: 'Autore',
              preview_button: 'Anteprima'
            },
          my_vocabularies:
            {
              title:' I miei dizionari',
              number_of_words: 'Numero di parole',
              edit_button: 'Modificare',
              add_button: {
                label: 'Nuovo dizionario'
              }
            },
          new_vocabulary: {
            title: 'Nuovo dizionario',
            form:{
              title: {
                label: 'Titolo',
              },
              description: {
                label: 'Descrizione',
              },
              image: {
                label: 'immagine',
                hint: "Trascina qui l'immagine o seleziona. L'immagine deve essere 48x48 pixel"
              },
              public:{
                label: 'Pubblico'
              },
              default_vocabulary: {
                label: 'Predefinito'
              },
              create_button: {
                label: 'Creare'
              },
              cancel_button: {
                label: 'Annullare'
              }
            },
            success_saved: {
              label: 'Successo',
              description: 'Dizionario salvato con successo'
            }
          },
          edit_vocabulary: {
            title: 'Modificare dizionario',
            form:{
              title: {
                label: 'Titolo',
              },
              description: {
                label: 'Descrizione',
              },
              image: {
                label: 'immagine',
                hint: "Trascina qui l'immagine o seleziona. L'immagine deve essere 48x48 pixel"
              },
              public:{
                label: 'Pubblico'
              },
              default_vocabulary: {
                label: 'Predefinito'
              },
              update_button : {
                label: 'Upgradare'
              },
              cancel_button: {
                label: 'Annullare'
              },

            },
            success_saved: {
              label: 'Successo',
              description: 'Dizionario salvato con successo'
            }
          },
          cards: {
            table:{
              word_header: 'Parola',
              translate_header: 'Traduzione',
              note_header: 'nota',
              sum_points_for_retry_header: 'Numero di punti da ripetere',
              real_points_header: 'Punti passati',
              loading: 'Loading...',
              note1: {
                label: 'Parola'
              },
              note2: {
                label: 'Traduzione'
              },
              note3: {
                label: 'Spiegazione'
              },
              status: {
                label: 'Stato',
                no_studied: 'Non studiato',
                studied: 'Studiato'
              },
              progress: {
                label: 'Progresso'
              },
              action:{
                label: 'Azioni'
              },
              last_view_date: {
                label: 'Ultima anteprima'
              },
              success_button: 'Successo',
              fail_button: 'Fallito',
              points_label: 'Punti',
              search: {
                placeholder: 'Ricerca...'
              },
              import_button: 'Importare',
              import_button_disabled_tooltip: 'Impossibile importare! Spunta le parole che vuoi importare',
              import_success_description: 'Importazione riuscita',

            },
            studied_button: 'Studiato',
            retry_button: 'Ripeti',
            delete_button: 'Eliminare',
            edit_word_title: 'Modificare parola',
            author_title: 'Autore'
          },
      },
        settings:{
          languages: {
            ru: {title: 'Russo'},
            en: {title: 'Inglese'},
            it: {title: 'Italiano'},
            es: {title: 'Spagnolo'},
            fr: {title: 'Francese'},
            de: {title: 'Tedesco'}
          },
          options:
            {
              title: 'Impostazioni generali',
              language: {
                label: 'Lingua'
              },
              key:{
                label: 'Chiave'
              },
              end_date:{
                label: 'Data di fine'
              },
              limitations:{
                label: 'Restrizioni'
              },
              default_vocabulary: {
                label: 'Dizionario predefinito'
              },
              extend_button: 'Estendi'
            },
          profile:{
            title: 'Profilo',
            name: {
              label: 'Nome'
            }
          },
          save_button: 'Salvare',
          success_saved: {
            label: 'Successo',
            description: 'Impostazioni salvate con successo'
          }

        },
        pay:{
          title: 'Il tuo aiuto',
          description: "Puoi aiutarci. Per fare questo, è necessario pagare l'abonimento mensile per un mese. Oppure si può pagare un anno in anticipo. Se vuoi semplicemente aiutare nello sviluppo del progetto, fai clic sul pulsante Dona. Tutti i soldi in arrivo vanno allo sviluppo del progetto. Tutti i pagamenti vengono elaborati entro 24 ore, quindi se hai pagato, ci vuole tempo per elaborare. Di solito non è più di 4 ore",
          month : {
            title: '1 mese',
            description: "1 mese con la possibilità di lavorare con le schede del dizionario. Accesso all'orologio, all'app web e ai plug-in del browser."
          },
          year : {
            title: '1 anno',
            description: "1 anno con la possibilità di lavorare con le schede del dizionario. Accesso all'orologio, all'app web e ai plug-in del browser. In futuro, la funzionalità sarà ampliata, ma per gli utenti con un abbonamento annuale, tutte le funzionalità saranno disponibili."
          },
          donate: {
            title: 'Donat',
            description: 'Si può semplicemente sostenere il nostro progetto. Tutte le donazioni non vanno sviluppo! Con una grande donazione, possiamo realizzare qualsiasi caratteristica individuale!'
          },
          paypal_tax: 'Commissione paypal',
          pay_button: 'Pagare'
        },
        login: {
          user: {
            label: 'Utente',
            placeholder: 'Email'
          },
          password: {
            label: "Parola d'accesso"
          },
          login_button: 'Login',
          forgot_password_button: "Hai dimenticato la parola d'accesso",
          old_password: 'Vecchia versione',
          login_error: 'Errore di autorizzazione!'
        }

    },
    menu:{
      vocabularies: {
        label: 'Dizionari'
      },
      games: {
        label: 'Giochi'
      },
      remember_it: {
        label: 'Ricordalo!'
      },
      settings: {
        label: 'Impostazioni'
      },

    }
});
