<template>
  <div class = "grid place-items-center h-screen overflow-hidden">
  <div class="w-full max-w-xs">
    <div class = "hero container max-w-screen-lg mx-auto pb-5 flex justify-center">
    <img src = "/images/logo.png">
    </div>
    <form class="bg-white shadow-md rounded px-8 pt-4 pb-8 mb-4">
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
          {{currentPage.user.label}}
        </label>
        <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" :placeholder="currentPage.user.placeholder" v-model ="login">
      </div>
      <div class="mb-6">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="password">
          {{currentPage.password.label}}
        </label>
        <input class="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" placeholder="******************" v-model ="password">
      </div>
      <div class="flex items-center justify-between">
        <button class="btn btn-primary w-32"  type="button" @click = "loginAction">
          {{currentPage.login_button}}
        </button>
        <a class="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" href="#">
          {{currentPage.forgot_password_button}}
        </a>
      </div>
      <br/>
      <div v-if = "result_from_server.text" class="alert alert-danger show flex items-center mb-2" role="alert">
        <AlertOctagonIcon class="w-6 h-6 mr-2" />
        {{result_from_server.text}}
      </div>
      <div class = "countries">
        <img  src = "images/flags/gb.svg" @click = "changeLang('en')">
        <img  src = "images/flags/de.svg" @click = "changeLang('de')">
        <img  src = "images/flags/fr.svg" @click = "changeLang('fr')">
        <img  src = "images/flags/it.svg" @click = "changeLang('it')">
        <img  src = "images/flags/es.svg" @click = "changeLang('es')">
        <img  src = "images/flags/ru.svg" @click = "changeLang('ru')">
      </div>
    </form>
    <p class="text-center text-white text-xs ">
      <a href = "https://remember-it.space/kabinet/">   {{currentPage.old_password}}</a>
    </p>
  </div>
  </div>
</template>

<script>
import lang from "@/views/login/lang";
import Api from "@/api.js";
import store from "@/store";

const api = new Api();

export default {
name: "Main.vue",
  mixins: [lang],
  data()
  {
    return {
      login: '',
      store: store,
      password: '',
      result_from_server:{
        text: '',
        status: null
      },
    }
  },
  methods:{
    async loginAction()
    {
      this.text =  this.currentPage.login_error;
      try
      {
        const loginData = await api.post('user/login',
          {
            data: {
                  login: this.login,
                  pass: this.password
                }
          });

        if (!loginData.status)
        {
          this.result_from_server.text =  this.currentPage.login_error;
        }
        else
        {
          this.store.options.user = loginData.data;
          this.$router.push({path: '/settings'});

        }

      }
      catch (e)
      {
        this.result_from_server.text = e.message
        this.status = 0;
      }

    },

    changeLang(lang)
    {
      this.store.options.user.lang = lang;
    }
  }
}
</script>

<style scoped>
  .countries
  {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  .countries img
  {
    width: 25px;
    cursor: pointer;
    border: 1px solid silver;
    margin: 2px
  }

</style>
