<template>
  <div class="item box intro-y" :ref="'vocabulary_'+this.id">
    <div class = "overflow-hidden h-36">
    <h2 class="font-small text-base text-center">{{current.title}}</h2>
    <div class="w-full border-t border-gray-200 dark:border-dark-5 border-dashed"></div>

   <div class="w-full flex flex-row align-middle items-center">
      <div>
        <img  :src = "current.image" class = "md:max-w-md mask mask-hexagon-2 m-2">
      </div>
      <div>
        <div><span  class="font-small inline-block text-base text-center">  {{currentPage.public_vocabularies.number_of_words}} <b>{{current.count}}</b></span> </div>
        <div><span  class="font-small inline-block text-base text-center">  {{currentPage.public_vocabularies.author}}: <b>{{current.author}}</b></span> </div>
      </div>
    </div>
    </div>
  <div class = "flex justify-center">
    <button class="btn btn-primary mr-1 mb-2 w-2/3 hover:btn-warning" @click = "viewVocabulary">{{currentPage.public_vocabularies.preview_button}}</button>
  </div>
  </div>
</template>

<script>
import lang from "./lang";

export default {
  name: "PublicVocabulary",
  mixins: [lang],
  props: ['id'],
  computed:{
    current()
    {
      return this.store.data.public_vocabularies.filter(item => item.id == this.id)[0];
    }
  },
  methods: {
    viewVocabulary()
    {
        this.$router.push({path: '/vocabularies/public/'+this.id});
    }
  }
}
</script>

<style scoped>

.item {
  @apply
  w-80 rounded-md px-5 pt-2  pb-5 px-3 sm:px-5 relative m-2 flex-auto shadow-md

}
h2
{
  @apply
  text-2xl font-medium leading-none mt-3 text-center
}
</style>
