import {reactive} from 'vue'

export default reactive({
    layout: {
        game_remember_it:{
            sec: 'sec',
            annotation: `
                You will be shown cards, you need to indicate whether you know this word or not. If not,
      the word will be sent for repetition (5 times), if yes, then it is considered that the word was repeated 5 times.
    <br/>
    <i>By default, cards are displayed from your dictionary set in the default settings in random order.
      You can set the dictionary you need and the order of the selection. For example, words can be displayed by the date of the last repetition.
      Also for prevention, you can specify that you should repeat the learned words.
      The display of cards can be selected as a list or one card at a time. And, it is also possible to display only the original word,
      and set the delay or translation for the rest of the words only after pressing the button.
      At the end of the game you will see the statistics of the words.
      When ready to start, press start. If you need to change settings, click Settings
By default, cards are displayed from your dictionary set in the default settings in random order.
      You can specify the dictionary you need and the order of the selection. For example, words can be displayed by the date of the last repetition.
      Also for prevention, you can specify that you should repeat the learned words.
      The display of cards can be selected as a list or one card at a time. And, it is also possible to display only the original word,
      and set the delay or translation for the rest of the words only after pressing the button.
      At the end of the game you will see the statistics of the words.
    </i>`,
            options_form: {
                vocabulary: {
                        label: 'Vocabulary'
                    },
                counter: {
                    label: 'Number of cards'
                },
                order: {
                    label: 'Order',
                    options: {
                        alf: {
                            label: 'Alphabetically'
                        },
                        rand: {
                            label: 'Randomly'
                        },
                        date: {
                            label: 'Date of last repetition'
                        },
                        min: {
                            label: 'Minimum number of repetitions'
                        }
                    }
                },
                retry: {
                    label: 'Include learned words'
                },
                counter_sec: {
                    label: 'Number of seconds'
                }
            },
            card: {
                    buttons:{
                        edit:{
                            label: 'Edit'
                        },
                        del:{
                            label: 'Delete'
                        },
                        remember:{
                            label: 'I remeber'
                        },
                        forgot:{
                            label: 'I forgot'
                        }
                    }
            },
            finish: {
                result_h1: {
                    5: 'Excellent',
                    4: 'Good',
                    3: 'Not bad',
                    2: 'Bad',
                    1: 'Awful'
                },
                result_description: {
                    5: 'You know all the words! Bravo!',
                    4: 'You know almost everything!',
                    3: 'You know most of the words, but it would not hurt to repeat some words.',
                    2: 'You know something, but it would be better to repeat everything',
                    1: 'The fact that you know something is pure coincidence. We recommend repeating all the words to you'
                },
                result_button:{
                    label: 'Results'
                },
                retry_button:{
                    label: 'Repeat'
                },
                max_time_label:  'Maximum time',
                mid_time_label:  'Mean time',
                min_time_label:  'Minimum time',
                last_time_is: 'Last time it was',
                present_label: 'Total correct words',
                this_words_need_try_label: 'It would not hurt to repeat the following words',
                result_title: 'Results',
                are_you_sure: 'Deletion confirmation',
                do_you_want: 'Are you sure you want to delete this card? Recovery is not possible!',
                del_button: 'Delete',
                cancel_button: 'Cancel',
                table:{
                    note1: {
                        label: 'Word'
                    },
                    note2: {
                        label: 'Translation'
                    },
                    note3: {
                        label: 'Explanation'
                    },
                    status: {
                        label: 'Status'
                    },
                    progress: {
                        label: 'Progress'
                    },
                    action:{
                        label: 'Действия'
                    },
                    success_button: 'Success',
                    fail_button: 'Failed',
                    points_label: 'points'
                },
            },
            start: {
              label: 'Start',
            },
            options: {
              label: 'Options',
            }

        },
        vocabularies: {
          public_vocabularies:
            {
              title: 'Public vocabularies',
              number_of_words: 'Number of words',
              author: 'Author',
              preview_button: 'Preview'
            },
          my_vocabularies:
            {
              title: 'My vocabularies',
              number_of_words: 'Number of words',
              edit_button: 'Edit',
              add_button: {
                label: 'New vocabulary'
              }
            },
          new_vocabulary: {
            title: 'New vocabulary',
            form:{
              title: {
                label: 'Title',
              },
              description: {
                label: 'Description',
              },
              image: {
                label: 'Image',
                hint: 'Drag an image here or select. Image must be 48x48 pixels'
              },
              public:{
                label: 'Public'
              },
              default_vocabulary: {
                label: 'Default'
              },
              create_button: {
                label: 'Create'
              },
              cancel_button: {
                label: 'Cancel'
              }
            },
            success_saved: {
              label: 'Success',
              description: 'Vocabulary is successfully saved'
            }
          },
          edit_vocabulary: {
            title: 'Edit the vocabulary',
            form:{
              title: {
                label: 'Title',
              },
              description: {
                label: 'Description',
              },
              image: {
                label: 'Image',
                hint: 'Drag an image here or select. Image must be 48x48 pixels'
              },
              public:{
                label: 'Public'
              },
              default_vocabulary: {
                label: 'Default'
              },
              update_button : {
                label: 'Update'
              },
              cancel_button: {
                label: 'Cancel'
              },

            },
            success_saved: {
              label: 'Success',
              description: 'Vocabulary is successfully saved'
            }
          },
          cards: {
            table:{
              word_header: 'Word',
              translate_header: 'Translation',
              note_header: 'Note',
              sum_points_for_retry_header: 'Number of points for repetition',
              real_points_header: 'Passed points',
              loading: 'Loading...',
              note1: {
                label: 'Word'
              },
              note2: {
                label: 'Translation'
              },
              note3: {
                label: 'Explanation'
              },
              status: {
                label: 'Status',
                no_studied: 'Not learned',
                studied: 'Learned'
              },
              progress: {
                label: 'Progress'
              },
              action:{
                label: 'Actions'
              },
              last_view_date: {
                label: 'Last viewed'
              },
              success_button: 'Success',
              fail_button: 'Failed',
              points_label: 'points',
              search: {
                placeholder: 'Search...'
              },
              import_button: 'Import',
              import_button_disabled_tooltip: 'Import is not possible! Please tick the words you want to import',
              import_success_description: 'Import is successfully completed',

            },
            studied_button: 'Learned',
            retry_button: 'Repetition',
            delete_button: 'Delete',
            edit_word_title: 'Edit the word',
            author_title: 'Author'
          },
      },
        settings:{
          languages: {
            ru: {title: 'Russian'},
            en: {title: 'English'},
            it: {title: 'Italian'},
            es: {title: 'Spanish'},
            fr: {title: 'French'},
            de: {title: 'German'}
          },
          options:
            {
              title: 'General settings',
              language: {
                label: 'Language'
              },
              key:{
                label: 'Key'
              },
              end_date:{
                label: 'End date'
              },
              limitations:{
                label: 'Limitations'
              },
              default_vocabulary: {
                label: 'Default vocabulary'
              },
              extend_button: 'Extend'
            },
          profile:{
            title: 'Profile',
            name: {
              label: 'Name'
            }
          },
          save_button: 'Save',
          success_saved: {
            label: 'Succes',
            description: 'Settings are successfully saved'
          }

        },
        pay:{
          title: 'Your help',
          description: 'You can help us. To do this, you need to pay a monthly subscription for a month. Or you can pay a year in advance. If you just want to help in the development of the project - click the donate button. All incoming money goes to the development of the project. All payments are processed within 24 hours, so if you paid, it takes time to process. Usually it is no more than 4 hours',
          month : {
            title: '1 month',
            description: ' 1 month with the opportunity to work with vocabulary cards. Access to watch, web app and browser plugins.'
          },
          year : {
            title: '1 year',
            description: ' 1 year with the ability to work with vocabulary cards. Access to watch, web app and browser plugins. In the future, the functionality will be expanded, but for users with an annual subscription - all features will be available.'
          },
          donate: {
            title: 'Donation',
            description: 'You can simply support our project. All donations go for development! With a large donation, we can make any individual feature! '
          },
          paypal_tax: 'paypal fee',
          pay_button: 'Pay'
        },
        login: {
          user: {
            label: 'User',
            placeholder: 'Email'
          },
          password: {
            label: 'Password'
          },
          login_button: 'Log in',
          forgot_password_button: 'Forgot Password',
          old_password: 'older version',
          login_error: 'Login error!'
        }

    },
    menu:{
      vocabularies: {
        label: 'Vocabularies'
      },
      games: {
        label: 'Games'
      },
      remember_it: {
        label: 'Remember it!'
      },
      settings: {
        label: 'Settings'
      },

    }
});
