<template>
  <div v-if = "current.play_status== 'started'"  class="item box" :ref="'card_'+current.id">
    <h2>{{current.note1}}</h2>
    <div class="card-body card-text border-t dark:border-dark-5 flex">
      <div class = "flex mt-24 w-[calc(100%-3rem)] absolute outline">
          <div class = "flex mt-2 justify-center items-center  content-center place-self-center w-full">
            <div class ="countdown-number align-self-center"><span>{{current.sec}}</span></div>
          </div>
      </div>
      <div class="flex flex-column justify-center w-full h-48 mt-4 outline align-center">
        <svg class="round" viewbox="0 0 100 100" width="300" height="300" >
          <circle cx="100" cy="100" r="50" />
        </svg>
      </div>
      </div>

    <div class = "card-actions border-solid  mt-6">
      <div class="w-full flex flex-wrap justify-between items-center">
        <div class = "w-1/2 flex flex-wrap justify-center items-center ">
          <button class="btn btn-success w-full mr-1 mb-2" @click = "success">{{currentPage.card.buttons.remember.label}}</button>
        </div>
        <div class = "w-1/2 flex flex-wrap justify-center items-center ">
          <button class="btn btn-danger w-full mr-1 mb-2" @click = "failed">{{currentPage.card.buttons.forgot.label}}</button>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if = "current.play_status == 'stopped'"  class="item box" :ref="'card_'+current.id" >
    <h2>{{current.note1}}</h2>
    <div class="card-body card-text border-t dark:border-dark-5 h-48 mt-4">
      <div v-if = "current.note2" v-bind:class="card_result"><span class = "text-center m-auto">{{current.note2}}</span></div>
      <div v-if = "current.note3" v-bind:class="card_result">{{current.note3}}</div>
    </div>
    <div class = "card-actions border-solid  mt-6">
      <div class="w-full flex flex-wrap justify-between items-center">

        <div class = "w-full flex flex-wrap justify-center items-center ">
          <button class="btn btn-danger w-full mr-1 mb-2" @click = "failed2">{{currentPage.card.buttons.forgot.label}}</button>
        </div>
      </div>
    </div>
  </div>
  <div v-else  :ref="'card_'+current.id"   class="item box  blur-sm" >
    <h2>{{current.note1}}</h2>
    <div class="card-body card-text border-t dark:border-dark-5 flex h-48 mt-4">
    </div>

  </div>
</template>

<script>
import lang from "../mixins.js";


export default {
name: "Card",
  props: ["id"],
  mixins: [lang],
  data()
  {
    return {
      play_status: '',
    }
  },
  mounted()
  {
    this.current.sec = parseInt(this.store.options.counter_sec);

  },
  watch:
  {
    seconds(val)
    {

      if (val == 0)
      {
        if (this.current.play_status == 'started')
        {
          // ele[i].style.animationDelay = delay;
         // animation: countdown 10s linear infinite forwards;
          this.current.play_status = 'stopped';
          this.current.result = false;
        }
      }
    },

    play_game_status(value)
    {
     if (value == 'started')
      {
        this.$nextTick(()=>{
          this.$refs['card_'+this.current.id].querySelector('.card-text svg circle').setAttribute('style', 'animation: countdown '+this.store.options.counter_sec+'s linear infinite forwards;')
          this.$refs['card_'+this.current.id].querySelector('.countdown-number').setAttribute('style', 'animation: countdown_number '+this.store.options.counter_sec+'s linear infinite forwards;')
        });

        this.current.interval = setInterval(() => {
          this.current.sec--;
        }, 1000)
      }
    },

    result(val)
    {
      var audio;
      if (val == true)
      {

        audio = new Audio('/sounds/good.mp3');

        this.current.play_status = 'stopped';
        this.current.result = true;

      }
      else
      {
        audio = new Audio('/sounds/bad.mp3');

        this.current.play_status = 'stopped';

        this.current.result = false;

      }
      audio.play();


        if (!this.current.force)
        {
          clearInterval(this.current.interval);


          this.store.data.cardsIndex = parseInt(this.store.data.cardsIndex)+1
        }
      


    },
  },

  computed:
      {
        card_ref()
        {
          return 'card-'+this.id;
        },
        current()
        {
          var id = this.id;
          return this.store.data.cards.find(item => item.id == id);
        },
        play_game_status()
        {
          return this.current.play_status;
        },
        seconds()
        {
          return this.current.sec;
        },
        result()
        {
          return this.current.result;
        },
        card_style()
        {
          if (this.current.result == false)
          {
            return 'alert-danger-soft show'
          }
          return 'alert-success-soft show';
        },
        card_result()
        {
          return 'show  flex items-center card-title  w-[calc(100%)] mt-2 alert '+ this.card_style;
        },

      },
      methods:
          {
            success()
            {

              this.current.result = true;

            },
            failed()
            {
              this.current.result = false;
            },
            failed2()
            {
              this.current.force = true;
              this.current.result = false;
            },
          }
}
</script>

<style scoped>

.item {
  @apply
  w-72 rounded-md px-5 pt-2  pb-5 px-3 sm:px-5 relative m-2 flex-auto shadow-md

}
h2
{
  @apply
  text-2xl font-medium leading-none mt-3 text-center
}

.more_menu {
  @apply
  absolute w-full flex
}

.card {
  overflow:inherit;
}

.card_body {

}
</style>
