import { createRouter, createWebHistory } from 'vue-router'
import SideMenu from '../layouts/side-menu/Main.vue'
import SimpleMenu from '../layouts/simple-menu/Main.vue'
import TopMenu from '../layouts/top-menu/Main.vue'
import ErrorPage from '../views/error-page/Main.vue'
import GameRememberIt from '../views/game-remember-it/Main.vue'
import Vocabularies from '../views/vocabularies/Main.vue';
import MyCards from '../views/vocabularies/MyCards.vue';
import PublicCards from '../views/vocabularies/PublicCards.vue';
import AddVocabulary from "@/views/vocabularies/AddVocabulary";
import Settings from "@/views/settings/Main.vue";
import Pay from '../views/pay/Main.vue';
import Login from '../views/login/Main.vue';

const routes = [
  {
    path: '/',
    component: SideMenu,
    children: [
      {
        path: '/game_remember_it',
        name: 'side-menu-game-remember-it',
        component: GameRememberIt
      },
      {
        path: '/vocabularies',
        name: 'side-menu-vocabularies',
        component: Vocabularies,
        children: [
          {
            path: '/vocabularies/my/:id/',
            component: MyCards,
          },
          {
            path: '/vocabularies/public/:id',
            component: PublicCards
          }
          ]

      },
      {
        path: '/settings',
        name: 'side-menu-settings',
        component: Settings,
      },
      {
        path: '/pay',
        name: 'side-menu-pay',
        component: Pay,
      }
    ]
  },
  {
    path: '/simple-menu',
    component: SimpleMenu,
    children: [
    ]
  },
  {
    path: '/top-menu',
    component: TopMenu,
    children: [
    ]
  },
  {
    path: '/error-page',
    name: 'error-page',
    component: ErrorPage
  },
  {
    path: '/:pathMatch(.*)*',
    component: ErrorPage
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  }
})

export default router
