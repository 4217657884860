import ru from "@/translations/ru";
import en from "@/translations/en";
import es from "@/translations/es";
import it from "@/translations/it";
import de from "@/translations/de";
import fr from "@/translations/fr";

// define a mixin object
const translate  = {
  ru: ru,
  en: en,
  es: es,
  it: it,
  de: de,
  fr: fr
};

export default translate;
