import {reactive} from 'vue'

export default reactive({
    layout: {
        game_remember_it:{
            sec: 'sec',
            annotation: `
                Vous verrez des cartes, vous devez indiquer si vous connaissez le mot ou non. Si non
     - le mot ira à la répétition (5 fois), si oui - alors on croit que le mot a été répété 5 fois.
     <br/>
     <i> Par défaut, les cartes sont affichés de manière aléatoire à partir de votre dictionnaire dans les paramètres par défaut.
     Vous pouvez définir le dictionnaire et l'ordre d'échantillonnage dont vous avez besoin. Par exemple, les mots peuvent être affichés par la date de la dernière répétition.
     Aussi pour la prévention, vous pouvez indiquer que vous devez répéter les mots appris.
     L'affichage des cartes peut être sélectionné par liste ou par carte. Il est également possible de n'afficher que le mot d'origine,
     et définir un délai ou une traduction pour le reste des mots uniquement après avoir appuyé sur le bouton.
     A la fin du jeu, vous verrez les statistiques des mots.
     Lorsque vous êtes prêt à démarrer, appuyez sur Start. Si vous souhaitez modifier les paramètres, cliquez sur paramètres
    </i>`,
            options_form: {
                vocabulary: {
                        label: 'Dictionnaire'
                    },
                counter: {
                    label: 'Nombre de cartes'
                },
                order: {
                    label: 'Ordre',
                    options: {
                        alf: {
                            label: 'Alphabétique'
                        },
                        rand: {
                            label: 'Aléatoire'
                        },
                        date: {
                            label: 'Date de la dernière répétition'
                        },
                        min: {
                            label: 'Nombre minimum de répétitions'
                        }
                    }
                },
                retry: {
                    label: 'Inclure les mots appris'
                },
                counter_sec: {
                    label: 'Nombre de secondes'
                }
            },
            card: {
                    buttons:{
                        edit:{
                            label: 'Éditer'
                        },
                        del:{
                            label: 'Supprimer'
                        },
                        remember:{
                            label: 'Je me souviens'
                        },
                        forgot:{
                            label: "J'ai oublié"
                        }
                    }
            },
            finish: {
                result_h1: {
                        5: 'Excellent',
                        4: 'Bien',
                        3: 'Pas mal',
                        2: 'Mal',
                        1: 'Horrible'
                },
                result_description: {
                        5: 'Vous savez tous les mots! Bravo!',
                        4: 'Vous savez presque tout! ',
                        3: 'Vous savez, la plupart des mots, mais il ne serait pas mal de répéter certains mots.',
                        2 :' Vous savez quelque chose, mais vous feriez mieux de tout répéter',
                        1: 'Ce que vous savez quelque chose est un pur hasard. Nous vous recommandons de répéter tous les mots'
                },
                result_button:{
                        label: 'Résultats'
                },
                retry_button:{
                        label: 'Répéter'
                },
                max_time_label: 'Durée maximale',
                mid_time_label: 'Durée moyenne',
                min_time_label: 'Durée minimale',
                last_time_is: "La dernière fois, c'était",
                present_label: 'Tous les mots corrects',
                this_words_need_try_label: 'Cela ne ferait pas de mal de répéter les mots suivants',
                result_title: 'Résultats',
                are_you_sure: 'Confirmation de suppression',
                do_you_want: 'Voulez-vous vraiment supprimer cette carte? La récupération est impossible!',
                del_button: 'Supprimer',
                cancel_button: 'Annuler',
                table:{
                    note1: {
                        label: 'Mot'
                    },
                    note2: {
                        label: 'Traduction'
                    },
                    note3: {
                        label: 'Explication'
                    },
                    status: {
                        label: 'Statut'
                    },
                    progress: {
                        label: 'Progrès'
                    },
                    action:{
                        label: 'Actions'
                    },
                    success_button: 'Succès',
                    fail_button: 'Échec',
                    points_label: 'points'
                },
            },
            start: {
              label: 'Démarrer',
            },
            options: {
              label: 'Réglages',
            }

        },
        vocabularies: {
          public_vocabularies:
            {
              title: 'Dictionnaires publics',
              number_of_words: 'Nombre de mots',
              author: 'Auteur',
              preview_button: 'Revue'
            },
          my_vocabularies:
            {
              title: 'Mes dictionnaires',
              number_of_words: 'Nombre de mots',
              edit_button: 'Éditer',
              add_button: {
                label: 'Nouveau dictionnaire'
              }
            },
          new_vocabulary: {
            title: 'Nouveau dictionnaire',
            form:{
              title: {
                label: 'Titre',
              },
              description: {
                label: 'Description',
              },
              image: {
                label: 'Image',
                hint: "Faites glisser une image ici ou sélectionnez. L'image doit être de 48x48 pixels"
              },
              public:{
                label: 'Public'
              },
              default_vocabulary: {
                label: 'Par défaut'
              },
              create_button: {
                label: 'Créer'
              },
              cancel_button: {
                label: 'Annuler'
              }
            },
            success_saved: {
              label: 'Succès',
              description: 'Dictionnaire enregistré avec succès'
            }
          },
          edit_vocabulary: {
            title: 'Éditer le dictionnaire',
            form:{
              title: {
                label: 'Titre',
              },
              description: {
                label: 'Description',
              },
              image: {
                label: 'Image',
                hint: "Faites glisser une image ici ou sélectionnez. L'image doit être de 48x48 pixels"
              },
              public:{
                label: 'Public'
              },
              default_vocabulary: {
                label: 'Par défaut'
              },
              update_button : {
                label: 'Mettre à jour'
              },
              cancel_button: {
                label: 'Annuler'
              },

            },
            success_saved: {
              label: 'Succès',
              description: "Dictionnaire enregistré avec succès"
            }
          },
          cards: {
            table:{
              word_header: 'Mot',
              translate_header: 'Traduction',
              note_header: 'Note',
              sum_points_for_retry_header: 'Nombre de points à répéter',
              real_points_header: 'Nombre de points passés',
              loading: 'Loading...',
              note1: {
                label: 'Mot'
              },
              note2: {
                label: 'Traduction'
              },
              note3: {
                label: 'Explication'
              },
              status: {
                label: 'Statut',
                no_studied: 'Non apris',
                studied: 'Apris'
              },
              progress: {
                label: 'Progrès'
              },
              action:{
                label: 'Actions'
              },
              last_view_date: {
                label: 'Dernière vue'
              },
              success_button: 'Succès',
              fail_button: 'Échec',
              points_label: 'points',
              search: {
                placeholder: 'Recherche...'
              },
              import_button: 'Importer',
              import_button_disabled_tooltip: "Impossible d'importer! S'il vous plaît marquer les mots avec la coche à importer",
              import_success_description: 'Importation réussie',

            },
            studied_button: 'Apris',
            retry_button: 'Répéter',
            delete_button: 'Supprimer',
            edit_word_title: 'Éditer le mot',
            author_title: 'Auteur'
          },
      },
        settings:{
          languages: {
            ru: {title: 'Russe'},
            en: {title: 'Anglais'},
            it: {title: 'Italien'},
            es: {title: 'Espagnol'},
            fr: {title: 'Français'},
            de: {title: 'Allemand'}
          },
          options:
            {
              title: 'Paramètres généraux',
              language: {
                label: 'Langue'
              },
              key:{
                label: 'Clé'
              },
              end_date:{
                label: 'Date de fin'
              },
              limitations:{
                label: 'Restrictions'
              },
              default_vocabulary: {
                label: 'Dictionnaire par défaut'
              },
              extend_button: 'Étendre'
            },
          profile:{
            title: 'Profil',
            name: {
              label: 'Nom'
            }
          },
          save_button: 'Enregistrer',
          success_saved: {
          label: 'Succès',
          description: 'Paramètres enregistrés avec succès'
          }

        },
        pay:{
          title: 'Votre aide',
          description: "Vous pouvez nous aider. Pour ce faire, vous devez payer un abonnement mensuel pour un mois. Ou vous pouvez payer pour un an à l'avance. Si vous voulez simplement aider au développement du projet - cliquez sur le bouton faire un don. Tout l'argent entrant va au développement du projet. Tous les paiements sont traités dans les 24 heures, donc si vous avez payé, il faut du temps pour le traitement. Habituellement, ce n'est pas plus de 4 heures",
          month : {
            title: '1 mois',
            description : "1 mois avec la possibilité de travailler avec des cartes de vocabulaire. Accès à la montre, à l'application Web et aux plugins de navigateur."
          },
          year : {
            title : '1 an',
            description: "1 an avec la possibilité de travailler avec des cartes de vocabulaire. Accès à la montre, à l'application Web et aux plugins de navigateur. À l'avenir, la fonctionnalité sera étendue, mais pour les utilisateurs avec un abonnement annuel - toutes les fonctionnalités seront disponibles."
          },
          donate: {
            title: 'Donat',
            description: "Vous pouvez simplement soutenir notre projet. Tous les dons ne vont pas au développement! Avec un don important, nous pouvons créer n'importe quelle fonctionnalité individuelle !"
          },
          paypal_tax: 'Commission paypal',
          pay_button: 'Payer',
        },
        login: {
          user: {
            label: 'Utilisateur',
            placeholder: 'Email'
          },
          password: {
            label: 'Mot de passe'
          },
          login_button: 'Connexion',
          forgot_password_button: 'Mot de passe oublié',
          old_password: 'ancienne version',
          login_error: "Erreur d'autorisation!"
        },

    },
    menu:{
      vocabularies: {
        label: 'Dictionnaires'
      },
      games: {
        label: 'Jeux'
      },
      remember_it: {
        label: 'Souviens-toi de ça!'
      },
      settings: {
        label: 'Paramètres'
      },

    }
});
