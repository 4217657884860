export default {
  menu: [
    {
      icon: 'BookIcon',
      pageName: 'side-menu-vocabularies',
      title: 'vocabularies',
    },
    {
      icon: 'PlayCircleIcon',
      pageName: 'side-menu-dashboard',
      title: 'games',
      subMenu: [
        {
          icon: 'ClockIcon ',
          pageName: 'side-menu-game-remember-it',
          title: 'remember_it'
        },
      ]
    },
    {
      icon: 'SettingsIcon',
      pageName: 'side-menu-settings',
      title: 'settings',
/*      subMenu: [
        {
          icon: 'ClockIcon ',
          pageName: 'side-menu-game-remember-it',
          title: 'Remember IT!'
        },
      ]*/
    },

  ]
}
