<template>
  <div class="md:container md:mx-auto">
    <div>
      <section>
        <div class = "intro-y box p-5 mt-12 sm:mt-5">
        <div class = "content-center flex justify-center flex-col lg:flex-row">
          <div  class = "justify-self-center" v-html = "result_image">
          </div>
          <div>
            <div class="w-full mx-auto text-center md:w-11/12 xl:w-9/12 md:text-center place-self-center">
              <div>
                <h1 class="mb-8 text-4xl font-extrabold leading-none tracking-normal text-gray-900 md:text-6xl md:tracking-tight">
                  {{result_h1}}
                </h1>
              </div>
              <div class="w-full flex flex-col items-center">
                <rating v-model = "result"></rating>
              </div>
              <p class="px-0 mb-8 text-lg text-gray-600 md:text-xl lg:px-24" v-html = "result_description">
              </p>

              <div class="mb-4 space-x-0 md:space-x-2 md:mb-8">
                <a href="#result"  class="btn btn-success m-2">
                  {{currentPage.finish.result_button.label}}
                  <svg class="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </a>
                <a href="/game_remember_it" class="inline-flex items-center justify-center w-full px-6 py-3 mb-2 text-lg bg-gray-100 rounded-2xl sm:w-auto sm:mb-0 m-2">
                  {{currentPage.finish.retry_button.label}}
                  <svg class="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"></path></svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
        </div>
        </div>
          <stat></stat>
        <div class = "intro-y box p-5 mt-12 sm:mt-5">

          <h2 class="font-medium text-base mr-auto">{{ this.currentPage.finish.result_title}}</h2>
          <ftable></ftable>
        </div>
      </section>

    </div>

  </div>

</template>

<script>
import rating from "@/components/ui/rating.vue";
import stat from "./stat.vue";
import ftable from "./ftable.vue";
import lang from "../mixins.js";
import Api from "@/api.js";


const api = new Api();
export default {
  name: "finish",
  mixins: [lang],
  async mounted()
  {

    await this.finish();
  },
  components: {
    rating, stat, ftable
  },

  methods: {

    async finish()
    {
      const finishData = await api.post('games/remember_it/finish',
          {
            data: {
                  category: this.store.options.vocabulary,
                  limit:    this.store.options.counter,
                  order:    this.store.options.order,
                  retry:    this.store.options.retry,
                  sec:      this.store.options.counter_sec,
                  results: this.store.data.cards,

                },
            auth: true
          });

      this.store.finish.result = finishData['data'];
    },


  },
  computed:{

    stat_color_max_sec_icon()
    {

    },
    stat_color_max_sec()
    {

      if (this.store.finish.result.stat_last)
      {
        if (this.store.finish.result.stat_last.max_sec > this.store.finish.result.stat.max_sec)
        {
          return 'stat-value text-error';
        }
        else if (this.store.finish.result.stat_last.max_sec < this.store.finish.result.stat.max_sec)
        {
          return 'stat-value text-success';
        }
        else
        {
          return 'stat-value text-success';
        }
      }
      else
      {
        return 'stat-value';
      }
    },
    currentIndex()
    {
      return this.store.data.cardsIndex;
    },
    result()
    {
      var precent = this.store.data.cards.filter(item => item.result);

      if (precent.length == this.store.data.cards.length)
      {
        return 5;
      }
      else if (precent.length > (this.store.data.cards.length/5)*4)
      {
        return 4
      }
      else if (precent.length > (this.store.data.cards.length/5)*3)
      {
        return 3;
      }
      else if (precent.length > (this.store.data.cards.length/5)*3)
      {
        return 2;
      }
      else
      {
        return 1;
      }
    },

    result_h1()
    {
          return this.currentPage.finish.result_h1[this.result];
    },

    result_description()
    {
      return this.currentPage.finish.result_description[this.result];
    },
    result_image()
    {

      switch (this.result)
      {
        case 5:
          return   '<img  src = "/images/5.svg" class = "md:max-w-md mask mask-hexagon-2">';
        case 4:
          return   '<img  src = "/images/4.svg" class = "md:max-w-md mask mask-hexagon-2">';
        case 3:
          return   '<img  src = "/images/3.svg" class = "md:max-w-md mask mask-hexagon-2">';
        case 2:
          return   '<img  src = "/images/2.svg" class = "md:max-w-md mask mask-hexagon-2">';
        case 1:
          return   '<img  src = "/images/1.svg" class = "md:max-w-md mask mask-hexagon-2">';
        default:
          return   '<img  src = "/images/1.svg" class = "md:max-w-md mask mask-hexagon-2">';

      }
      return   '<img  src = "/images/1.svg" class = "md:max-w-md mask mask-hexagon-2">';
    },

  },

  watch:
      {
        currentIndex(val)
        {
          if ((val)  == this.store.data.cards.length)
          {
            setTimeout(()=> {
              var audio = new Audio('/sounds/game_win.mp3');
              this.store.ui.page = 'finish';
              audio.play();
            }, 1000);

          }
          else
          {
            this.store.data.cards[val].play_status = 'started';
          }
        }

      }

}
</script>

<style scoped>




.dropdown-content a
{
  font-family: system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.item {
  @apply
  w-52 h-64 flex-auto m-1.5 shadow-md
  bg-white
  text-gray-600
  text-xl font-bold
  flex items-center content-center justify-center
  rounded
}



</style>
