<template>
  <div class="flex flex-col items-center py-6 space-y-3">
    <div class="flex space-x-3">
      <svg v-for = "n in 5" :class="color(n)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
name: "rating",
  props: ["modelValue"],
  methods:{
      color(n)
      {
        if (n <= this.modelValue)
        {
          return 'w-12 h-12 text-yellow-500';
        }
        return 'w-12 h-12 text-gray-500';
      }
  }
}
</script>

<style scoped>

</style>