<template>
  <div  class = "box">
    <div class="modal-header">
      <h2 class="font-medium text-base mr-auto">{{currentPage.new_vocabulary.title}}</h2>
    </div>
    <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
      <div class="col-span-12">
        <label  class="form-label">{{currentPage.new_vocabulary.form.title.label}}</label>
        <input
          id="pos-form-1"
          type="text"
          class="form-control flex-1"
          v-model="title"
        />
      </div>
      <div class="col-span-12">
        <label  class="form-label">{{currentPage.new_vocabulary.form.description.label}}</label>
        <ClassicEditor v-model="description" />
      </div>
      <div class="col-span-12">
        <label  class="form-label">{{currentPage.new_vocabulary.form.image.label}}</label>
        <Dropzone ref-key="dropzoneMultipleRef" :options="{
                            url: 'https://remember-it.space/upload/',
                            thumbnailWidth: 150,
                            maxFilesize: 0.5,

                          }" class="dropzone">
          <div class="text-lg font-medium">
          </div>
          <div class="text-gray-600">
            {{currentPage.new_vocabulary.form.image.hint}}
          </div>
        </Dropzone>

      </div>

      <div class="col-span-12">
        <input  class="form-check-input" type="checkbox" v-model="public" />
        <label class="form-check-label" >{{currentPage.new_vocabulary.form.public.label}}</label>
      </div>

      <div class="col-span-12">
        <input  class="form-check-input" type="checkbox" v-model="default_vocabulary" />
        <label class="form-check-label" >{{currentPage.new_vocabulary.form.default_vocabulary.label}}</label>
      </div>
    </div>
    <div class="modal-footer text-right">
      <button
        type="button"
        class="btn btn-outline-secondary w-32 mr-1"
        @click = "store.ui.views.vocabularies.add_form = false;"
      >
        {{currentPage.new_vocabulary.form.cancel_button.label}}
      </button>
      <button type="button" class="btn btn-primary w-32"  @click = "addVocabularySave">
        {{currentPage.new_vocabulary.form.create_button.label}}
      </button>
    </div>
    <div class="text-center">
      <!-- BEGIN: Notification Content -->
      <div id="success-notification-content" class="toastify-content hidden flex">
        <CheckCircleIcon class="text-theme-9" />
        <div class="ml-4 mr-4">
          <div class="font-medium">{{currentPage.new_vocabulary.success_saved.label}}</div>
          <div class="text-gray-600 mt-1">
            {{currentPage.new_vocabulary.success_saved.description}}
          </div>
        </div>
      </div>
      <!-- END: Notification Content -->
    </div>
  </div>

</template>

<script>
import lang from "./lang";
import Api from "@/api";
import Toastify from 'toastify-js';
const api = new Api();

export default {
  name: "AddVocabulary",
  mixins: [lang],
  props: ['id'],
  data()
  {
    return {
      title: '',
      description: '',
      public: false,
      default_vocabulary: false

    }
  },
  computed:{
    current()
    {
      return this.store.data.my_vocabularies.filter(item => item.id == this.id)[0];
    }
  },
  methods:{
    edit_vocabulary()
    {
      this.store.ui.views.vocabularies.add_form = false;
      this.store.ui.views.vocabularies.edit_form = true;
    },
    async addVocabularySave()
    {

       var result =  await api.post('vocabulary/add',
        {
          data: {
            title:              this.title,
            description:        this.description,
            public:             this.public,
            default_vocabulary: this.default_vocabulary
          },
          auth: true
        });

       if (result.status == '1')
       {

         this.store.data.my_vocabularies = await api.get('vocabulary/my', {auth: true});


         if (this.default_vocabulary == true)
         {
           //обновим еще пользователя

            this.store.options.user.default_category = result.id;
         }
           Toastify({
             node: cash("#success-notification-content")
               .clone()
               .removeClass("hidden")[0],
             duration: -1,
             newWindow: true,
             close: true,
             gravity: "top",
             position: "right",
             stopOnFocus: true
           }).showToast();


       }

    },

    // Basic non sticky notification
    basicNonStickyNotificationToggle() {
      Toastify({
        node: cash('#basic-non-sticky-notification-content')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
  }
}
</script>

<style scoped>


.corner-ribbon{
  width: 200px;
  background: #e43;
  position: absolute;
  top: 25px;
  left: -50px;
  text-align: center;
  line-height: 50px;
  letter-spacing: 1px;
  color: #f0f0f0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

/* Custom styles */

.corner-ribbon.sticky{
  position: fixed;
}

.corner-ribbon.shadow{
  box-shadow: 0 0 3px rgba(0,0,0,.3);
}

/* Different positions */

.corner-ribbon.top-left{
  top: 25px;
  left: -50px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.corner-ribbon.top-right{
  top: 25px;
  right: -50px;
  left: auto;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.corner-ribbon.bottom-left{
  top: auto;
  bottom: 25px;
  left: -50px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.corner-ribbon.bottom-right{
  top: auto;
  right: -50px;
  bottom: 25px;
  left: auto;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

/* Colors */


.item {
  @apply
  w-80 rounded-md px-5 pt-2  pb-5 px-3 sm:px-5 relative m-2 flex-auto shadow-md

}
h2
{
  @apply
  text-2xl font-medium leading-none mt-3 text-center
}
</style>
