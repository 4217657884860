<template>
  <div class="container">

    <div class = "box">

  <div class="mx-4 sm:p-6 mt-5">
      <annotation></annotation>
      <options-form></options-form>
        <div class="flex flex-row m-8">
          <button class="btn btn-primary w-24 inline-block mr-1 mb-2"  @click = "start">{{currentPage.start.label}}</button>
          <button class="btn btn-secondary w-24 inline-block mr-1 mb-2"  @click = "showOptions">{{currentPage.options.label}}</button>
        </div>
  </div>
  </div>
  </div>
</template>

<script>
import Annotation from "./Annotation.vue";
import OptionsForm from "./OptionsForm.vue";
import lang from "../mixins.js";
import Api from "@/api.js";
import {defineComponent} from "vue";

const api = new Api();

export default defineComponent({
  name: "Start",
  components: {
    OptionsForm,
    Annotation
  },
  mixins: [lang],
  mounted()
  {
    this.store.options.vocabulary = parseInt(this.store.options.user.default_category);
  },
  methods:
  {

    showOptions()
    {
      this.store.options.showOptions =  !this.store.options.showOptions;
    },
    async start()
    {
      const cardData = await api.get('games/remember_it',
        {
          params:
            {
              category: this.store.options.vocabulary,
              limit:    this.store.options.counter,
              order:    this.store.options.order,
              retry:    this.store.options.retry
            },
          auth: true
        });

      this.store.data.cards = cardData['data'];
      this.store.ui.page = 'process';
    },
  }
});

</script>

<style scoped>

</style>
