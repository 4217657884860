import store from "@/store";

export default class Api
{
	constructor(config)
	{
		this.url = store.options.api_url;
		this.key = store.options.user.key;
	}

	async post(object, options)
	{
		var url = this.url+'/'+object;
		var params = {};
		var urlParams = [];

		if (options.auth)
		{
			urlParams.push('key='+	store.options.user.key);
		}

		if (options.params)
		{

			for (const [key, value] of Object.entries(options.params))
			{
				urlParams.push(key +'='+encodeURI(value));
			}
		}

		if (urlParams)
		{
			url += '?'+urlParams.join('&');
		}


		if (options && options.headers)
		{
			params.headers = options.headers;
		}

		params.method = 'POST';
		params.body = JSON.stringify(options.data);


		const response = await fetch(url, params);

		if (response.ok)
		{
			const text = await response.text();

			try {
				var data = 	JSON.parse(text);
			} catch (e) {
				throw new Error("Api result not correct!");
				return [];
			}


			return  data; // Try to parse it as json

		} else {
			throw new Error("Can't connect to api");
		}
		return [];
	}

	async get(object, options)
	{
		var url = this.url+'/'+object;
		var params = {};


		if (options && options.headers)
		{
			params.headers = options.headers;
		}

		var urlParams = [];

		if (options.auth)
		{
			urlParams.push('key='+store.options.user.key);
		}

		if (options.params)
		{

			for (const [key, value] of Object.entries(options.params))
			{
				urlParams.push(key +'='+encodeURI(value));
			}
		}

		if (urlParams)
		{
			url += '?'+urlParams.join('&');
		}
		params.method = 'get';


		const response = await fetch(url);

		if (response.ok)
		{
			const text = await response.text();
			var resultData;
			try {
				resultData = 	JSON.parse(text);
				return resultData;
			} catch (e) {
				throw new Error("Api result not correct!");
			}

			return  []; // Try to parse it as json

		} else {
			throw new Error("Can't connect to api");
		}
		return [];
	}

	async login(data)
	{
		var result = await this.post('user/login', data);

		store.user = result;

		return result;
	}


	async loginByKey(key)
	{
		var result = await this.get('user?key='+key);

		store.user = result;

		return result;
	}
}
