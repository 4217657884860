<template>
  <div class="container form-control mt-5" v-show="store.options.showOptions">
    <div class="mt-2">
        <label>  <span>{{currentPage.options_form.vocabulary.label}}</span></label>
        <div >
          <TomSelect  :value = "store.options.user.default_category">
            <option v-for = "item in store.data.vocabularies" :value = "item.id">{{item.title}}</option>
          </TomSelect>
        </div>
    </div>

    <div class="mt-2">
      <label>
        <span >{{currentPage.options_form.order.label}}</span>
      </label>
      <div>
        <TomSelect  v-model = "store.options.order"  :value = "store.options.user.default_category">
          <option v-for = "item in store.options.orders" :value = "item.id" >{{currentPage.options_form.order.options[item.id].label}}</option>
        </TomSelect>
      </div>
    </div>

    <div class="mt-2">
      <label>
        <span>{{currentPage.options_form.counter.label}}</span>
      </label>
      <div>
        <input class="form-control" min = "5" step = "5" type="number" v-model = "store.options.counter" >
      </div>
    </div>

    <div class="mt-2">
      <label>
        <span>{{currentPage.options_form.counter_sec.label}}</span>
      </label>
      <div>
        <input class="form-control" min = "5" step = "5" type="number" v-model = "store.options.counter_sec" >
      </div>
    </div>

    <div class="mt-2">
      <label>
        <span>{{currentPage.options_form.retry.label}}</span>
      </label>
      <div>
        <input type="checkbox" :checked="checked" class="toggle toggle-primary" v-model = "store.options.retry">
      </div>
    </div>
  </div>
</template>

<script>
import lang from "../mixins.js";
import Api from "@/api.js";



var api = new Api();

export default {
  name: "OptionsForm",
  mixins: [lang],
  async mounted() {
    this.store.data.vocabularies = await api.get('vocabulary/read', {auth: true})
  },
  computed:{
      selected()
      {
        //(store.options.order == item.id) ? 'selected': ''
        return 'selected';
      }
  },
  emits: ['update:value'],

}

</script>

<style>
</style>
