import translate from "@/translate.js";
import store from "@/store";

// define a mixin object
    const lang  = {
        computed: {
            currentPage()
            {
                return translate[this.store.options.user.lang].layout.login;
            }
        }
    }

    export default lang;
