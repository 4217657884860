import main from './main'
import sideMenu from './side-menu'
import simpleMenu from './simple-menu'
import topMenu from './top-menu'
import {reactive} from 'vue'

const store = reactive({
    state: {
      main: main,
      sideMenu: sideMenu,
      simpleMenu: simpleMenu,
      topMenu: topMenu
    },
    options: {
      api_url: 'https://api.remember-it.space/v2',
      user: {
        key: '',
        default_category: '',
        lang: 'en',
        name: '',
        end_date: ''
      },
      counter: 10,
      counter_sec: 10,
      lang: 'ru',
      languages: ['en', 'de', 'es', 'fr', 'it', 'ru'],
      order: 'rand',
      retry: false,
      vocabulary: '',
      showOptions: false,
      orders: [{id: 'rand'}, {id:'alf'}, {id:'date'}, {id:'min'}],
      current: 0,
    },
    finish: {
      result: {
        stat:{},
        stat_last:{},
        cards:[],
      }
    },
    ui: {
      page: 'start',
      views:{
        vocabularies: {
          edit_form: false,
          create_form: false,
          currentId: false,
        },

      }
    },
    data: {
      cards: [],
      searchWord: '',
      importToId: 0,
      sortBy: ''
    },
  }
);

export function useStore() {
  return store
}

export default store;








