<template>
  <div>
    <div v-if = "this.store.data.cards.editId">
        <div>
          <div id =  "editBox" class = "box" ref = "editBox">
            <div class="modal-header">
              <h2 class="font-medium text-base mr-auto">{{currentPage.cards.edit_word_title}}</h2>
            </div>
            <div class = "flex flex-row m-2">
              <div class = "flex items-center justify-center">
                <button  v-if = "currentIndex > 0"  class="btn btn-primary w-12 inline-block mr-1 mb-2" @click = "prevIndex">«</button>
                <button v-else  class="btn btn-second w-12 inline-block mr-1 mb-2">«</button>
              </div>
              <div class = "w-full">
                <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
                  <div class="col-span-12">
                    <label  class="form-label"> {{currentPage.cards.table.word_header}}</label>
                    <input
                      type="text"
                      class="form-control flex-1"
                      v-model = "currentEdit.note1"
                    />
                  </div>
                  <div class="col-span-12">
                    <label  class="form-label">{{currentPage.cards.table.translate_header}}</label>
                    <input
                      type="text"
                      class="form-control flex-1"
                      v-model = "currentEdit.note2"
                    />
                  </div>
                  <div class="col-span-12">
                    <label  class="form-label">{{currentPage.cards.table.note_header}}</label>
                    <input
                      type="text"
                      class="form-control flex-1"
                      v-model = "currentEdit.note3"
                    />
                  </div>
                  <div class="col-span-12">
                    <label  class="form-label">{{currentPage.cards.table.sum_points_for_retry_header}}</label>
                    <input
                      type="number"
                      class="form-control flex-1"
                      v-model = "currentEdit.max_count"
                    />
                  </div>
                  <div class="col-span-12">
                    <label  class="form-label">{{currentPage.cards.table.real_points_header}}</label>
                    <input
                      type="number"
                      class="form-control flex-1"
                      v-model = "currentEdit.real_count"
                    />
                  </div>
                  <div class="col-span-12">
                    <label  class="form-label">{{currentPage.cards.table.status.label}}</label>
                    <TomSelect  :value = "currentEdit.status">
                      <option   value = "1">{{currentPage.cards.table.status.no_studied}}</option>
                      <option   value = "2">{{currentPage.cards.table.status.studied}}</option>
                    </TomSelect>
                  </div>
                </div>
              </div>
              <div class = "flex items-center justify-center">
                <button  v-if = "currentIndex < (this.store.data.cards.length-1)"  class="btn btn-primary w-12 inline-block mr-1 mb-2" @click = "nextIndex">»</button>
                <button v-else  class="btn btn-second w-12 inline-block mr-1 mb-2">»</button>
              </div>
            </div>

            <div class="modal-footer text-right">
              <button
                type="button"
                class="btn btn-outline-secondary w-32 mr-1"
                @click = "store.ui.views.vocabularies.edit_form = false;"
              >
                {{currentPage.new_vocabulary.form.cancel_button.label}}
              </button>
              <button type="button" class="btn btn-primary w-32" @click = "updateCard" >
                {{currentPage.edit_vocabulary.form.update_button.label}}
              </button>
            </div>

            <div class="text-center">
              <div id="success-notification-content" class="toastify-content hidden flex">
                <CheckCircleIcon class="text-theme-9" />
                <div class="ml-4 mr-4">
                  <div class="font-medium">{{currentPage.edit_vocabulary.success_saved.label}}</div>
                  <div class="text-gray-600 mt-1">
                    {{currentPage.edit_vocabulary.success_saved.description}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div v-if = "this.store.data.cards && this.store.data.vocabulary">
      <div class="intro-y box col-span-12 2xl:col-span-6 mt-5">
        <div v-if = "store.data.cards">
          <div class = "bg-gray-200">
            <div class="flex flex-row w-full">
              <div class = "mt-2 mb-2 w-1/2">
                <button v-if = "isChecked" class="btn btn-success mr-2" @click = "studiedCards">
                  <CheckCircleIcon class="w-4 h-4 mr-2"  />     {{currentPage.cards.studied_button}}
                </button>
                <Tippy v-else tag="a" href="javascript:;" class="tooltip btn btn-second mr-2" v-bind:content="currentPage.cards.table.import_button_disabled_tooltip">
                  <CheckCircleIcon class="w-4 h-4 mr-2" />
                  {{currentPage.cards.studied_button}}
                </Tippy>

                <button v-if = "isChecked" type="button" class="btn btn-primary w-24 mr-2" @click = "retryCards" >
                  <RotateCcwIcon class="w-4 h-4 mr-2" />
                  {{currentPage.cards.retry_button}}
                </button>
                <Tippy v-else tag="a" href="javascript:;" class="tooltip btn btn-second  mr-2" v-bind:content="currentPage.cards.table.import_button_disabled_tooltip">
                  <RotateCcwIcon class="w-4 h-4 mr-2" />
                  {{currentPage.cards.retry_button}}
                </Tippy>

                <button  v-if = "isChecked" class="btn btn-danger w-32 mr-2" @click = "deleteCards" >
                  <TrashIcon class="w-4 h-4 mr-2" />    {{currentPage.cards.delete_button}}
                </button>
                <Tippy v-else tag="a" href="javascript:;" class="tooltip btn btn-second w-32 mr-2" v-bind:content="currentPage.cards.table.import_button_disabled_tooltip">
                  <TrashIcon class="w-4 h-4 mr-2" />
                  {{currentPage.cards.delete_button}}
                </Tippy>
              </div>
              <div class = "flex flex w-1/2 justify-end"></div>
              <div class = "mt-2 mb-2">
                <input
                  type="text"
                  class="form-control w-56 box pr-10 placeholder-theme-13"
                  v-bind:placeholder="currentPage.cards.table.search.placeholder"
                  v-model = "store.data.searchWord"
                />
                <SearchIcon
                  class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
                />
              </div>
            </div>
          </div>
          <div class="intro-y box col-span-12 2xl:col-span-6">
            <div class = "content-container">
              <div class = "w-full overflow-auto h-full">
                <table class="mt-0 border-collapse w-full" style = "overflow: auto;">
                  <thead style = "position: -webkit-sticky; position: sticky; top: 0px; z-index:100">
                  <tr>
                    <th class="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                      <input  class="form-check-input" type="checkbox" value="" @change="setAllChecked" />
                    </th>
                    <th class="th_item"  @click = "sortBy('note1')">{{currentPage.cards.table.note1.label}} <span v-if = "store.data.sortBy == 'note1'">↓</span></th>
                    <th class="th_item"  @click = "sortBy('note2')">{{currentPage.cards.table.note2.label}}  <span v-if = "store.data.sortBy == 'note2'">↓</span></th>
                    <th class="th_item"  @click = "sortBy('note3')">{{currentPage.cards.table.note3.label}}  <span v-if = "store.data.sortBy == 'note3'">↓</span></th>
                    <th class="th_item">{{currentPage.cards.table.last_view_date.label}}</th>
                    <th class="th_item">{{currentPage.cards.table.progress.label}}</th>
                    <th class="th_item">{{currentPage.cards.table.progress.label}}</th>
                    <th class="th_item">{{currentPage.cards.table.action.label}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for = "(cardItem, index) in store.data.cards"  :class = "tr(index)">
                    <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                      <input  class="form-check-input" type="checkbox" v-model = "cardItem.checked"/>
                    </td>
                    <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                      <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase"> {{currentPage.cards.table.note1.label}} </span>
                      <span  v-if = "wordFound(cardItem, 'note1')" v-html = "highlight(cardItem.note1)"></span>
                      <span v-else>{{cardItem.note1}}</span>
                    </td>
                    <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                      <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase"> {{currentPage.cards.table.note2.label}} </span>
                      <span v-if = "wordFound(cardItem, 'note2')" v-html = "highlight(cardItem.note2)"></span>
                      <span v-else>{{cardItem.note2}}</span>
                    </td>
                    <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                      <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">{{currentPage.cards.table.note3.label}}</span>
                      <span v-if = "wordFound(cardItem, 'note3')" v-html = "highlight(cardItem.note3)"></span>
                      <span v-else>{{cardItem.note3}}</span>
                    </td>
                    <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                      <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">{{currentPage.cards.table.note3.label}}</span>
                      <span >{{cardItem.last_view_date}}</span>
                    </td>
                    <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                      <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">{{currentPage.cards.table.progress.label}}</span>
                      <progress class="progress" v-bind:value="cardItem.real_count" :max="cardItem.max_count"></progress>
                    </td>
                    <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                      <span  v-if = "cardItem.status == 2" class="text-center text-xs inline-block py-1 px-2.5 bg-theme-9 leading-none text-center whitespace-nowrap align-baseline font-bold bg-yellow-500 text-white rounded">{{currentPage.cards.table.status.studied}}</span>
                      <span v-else class="text-center text-xs inline-block py-1 px-2.5 bg-theme-13 leading-none text-center whitespace-nowrap align-baseline font-bold bg-yellow-500 text-white rounded">{{currentPage.cards.table.status.no_studied}}</span>
                    </td>
                    <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
                        <div @click = "edit(cardItem.id)">
                          <EditIcon class = "cursor-pointer text-gray-600"></EditIcon>
                        </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          Loading...
        </div>
      </div>
    </div>

    <div id="success-notification-content" class="toastify-content hidden flex">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">{{currentPage.edit_vocabulary.success_saved.label}}</div>
        <div class="text-gray-600 mt-1">
          {{currentPage.cards.table.import_success_description}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import Api from "@/api.js";
import lang from "@/views/vocabularies/lang";

import async from "async";
import Toastify from "toastify-js";
import table from "@/mixins/table";

const api = new Api();
export default {
  name: "MyCards",
  mixins: [lang, table],
  props: ['id'],
  setup()
  {
    const editorData = ref('');

    return {
      editorData
    }

  },
  data()
  {
    return {
      new_vocabulary: false
    }
  },
  methods:{
    wordFound(item, index)
    {
      return !!(this.store.data.searchWord && item[index] && typeof(item[index]) == 'string' && item[index].toLowerCase().includes(this.searchWord.trim().toLowerCase()))
    },
    edit(id)
    {
      this.store.data.cards.editId = id;
      var element = this.$refs['editBox'];
      console.log('element', element);
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },
    nextIndex()
    {
      var card = this.store.data.cards[this.currentIndex+1];
      if (card)
      {
        this.store.data.cards.editId = card.id;
      }
    },
    prevIndex()
    {
      var card = this.store.data.cards[this.currentIndex-1];
      if (card)
      {
        this.store.data.cards.editId = card.id;
      }
    },
    tr(index)
    {
      if (this.currentIndex  == index)
      {
        return 'tr_active'
      }
     },
    async updateCard()
    {
      var result =  await api.post('card/update/'+this.currentEdit.id,
        {
          data: {
            note1:              this.currentEdit.note1,
            note2:              this.currentEdit.note2,
            note3:              this.currentEdit.note3,
            real_count:         this.currentEdit.real_count,
            max_count:          this.currentEdit.max_count,
            status:             this.currentEdit.status
          },
          auth: true
        });

      if (result.status == '1')
      {

        Toastify({
          node: cash("#success-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: -1,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      }
    },
    async studiedCards()
    {
      var cards = this.checkedItems;

      var postCards = cards.map((item) => {
        return {
          id: item.id,
          status: 2
        }
      });

      var result =  await api.post('card/update_many',
        {
          data: postCards,
          auth: true
        });

      if (result.status == '1')
      {

        Toastify({
          node: cash("#success-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: -1,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();

        //теперь обновим сами карточки

        cards.forEach((item) =>
        {
          //обновим карточку
          var result  = this.store.data.cards.filter(itemf=>
          {
            return itemf.id == item.id;
          });

          result[0].status = 2;
        });
      }
    },
    async retryCards()
    {
      var cards = this.checkedItems;

      var postCards = cards.map((item) => {
        return {
          id: item.id,
          status: 1,
          max_count: item.max_count+100
        }
      });

      var result =  await api.post('card/update_many',
        {
          data: postCards,
          auth: true
        });

      if (result.status == '1')
      {

        Toastify({
          node: cash("#success-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: -1,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();

        //теперь обновим сами карточки

        cards.forEach((item) =>
        {
          //обновим карточку

          var result  = this.store.data.cards.filter(itemf=>
          {
            return itemf.id == item.id;
          });
          result[0].status = 1;
          result[0].max_count = item.max_count+100;
        });

      }
    },
    async deleteCards()
    {
      var cards = this.checkedItems;

      var postCards = cards.map((item) => {
        return {
          id: item.id,
          hidden: true
        }
      });

      var result =  await api.post('card/update_many',
        {
          data: postCards,
          auth: true
        });


      if (result.status == '1')
      {

        Toastify({
          node: cash("#success-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: -1,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();

        //теперь обновим сами карточки

        cards.forEach((item) =>
        {
          //обновим карточку

          var result  = this.store.data.cards.filter(itemf=>
          {
            return itemf.id == item.id;
          });
          result[0].hidden = item.hidden;
        });
      }

    },
  },
  computed:
  {
      isChecked()
      {
        var sum  = this.store.data.cards.filter(item=> {
          return item.checked == true;
        });
        return sum.length > 0;
      },
      currentEdit()
      {
        if (this.store.data.cards.editId)
        {
          return this.store.data.cards[this.currentIndex];
        }
        return this.store.data.cards[0];
      },
      currentIndex()
      {
        if (this.store.data.cards.editId)
        {
            var ind = null;
            this.store.data.cards.forEach((item, index)=>{
              if (item.id == this.store.data.cards.editId)
              {
                ind = index;
              }
          });
          return ind;
        }
        return 0;
      }
    },
  async mounted()
  {
    this.store.data.cards         = await api.get('card/read_by_vocabulary/'+this.$route.params.id, {auth: true});
    this.store.data.vocabulary    = await api.get('vocabulary/read/'+this.$route.params.id, {auth: true});
    this.store.data.cards         = await api.get('card/read_by_vocabulary/'+this.$route.params.id, {auth: true});

    this.store.data.cards.forEach(item=> {
      item.checked = false;
    });

    this.store.data.hidden_cards  =  this.store.data.cards;
  },
}
</script>

<style scoped>
.th_item {
  @apply
  p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell cursor-pointer
}

.tr_active {
 @apply
 bg-gray-200
}
.content-container {
  height: calc(100vh - theme('spacing.52'));
}

</style>
