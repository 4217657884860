import translate from "@/translate.js";
import store from '@/store';

// define a mixin object
  const lang  = {
      computed: {
          currentPage()
          {
              return translate[store.options.user.lang].layout.vocabularies;
          }
      }
  }

    export default lang;
