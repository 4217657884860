<template>
  <div class="overflow-hidden item box intro-y" :ref="'vocabulary_'+this.id">

    <div class = "overflow-hidden h-36">

      <div  v-if = "store.options.user.default_category == current.id" class="corner-ribbon top-right sticky bg-shadow" style="background-color:#1c3faa">{{currentPage.edit_vocabulary.form.default_vocabulary.label}}</div>

      <h2 class="font-small text-base text-center">{{current.title}}</h2>

      <div class="w-full border-t border-gray-200 dark:border-dark-5 border-dashed"></div>

      <div class="w-full flex flex-row align-middle items-center">
        <div>
          <img  :src = "current.image" class = "md:max-w-md mask mask-hexagon-2 m-2">
        </div>
        <div>
          <div v-if = "current.public == 1">
            <span class="text-center text-xs inline-block py-1 px-2.5 bg-theme-9 leading-none text-center whitespace-nowrap align-baseline font-bold bg-yellow-500 text-white rounded">{{currentPage.edit_vocabulary.form.public.label}}</span>
          </div>
          <div><span  class="font-small inline-block text-base text-center">    {{currentPage.my_vocabularies.number_of_words}} <b>{{current.count}}</b></span> </div>

        </div>
      </div>
    </div>
    <div class = "flex justify-center">
      <button class="btn btn-warning mr-1 mb-2 hover:btn-dark" @click = "editVocabulary"> <EditIcon class="w-4 h-4 mr-2"  /> {{currentPage.my_vocabularies.edit_button}}</button>
      <button class="btn btn-primary mr-1 mb-2  hover:btn-dark" @click = "viewVocabulary">{{currentPage.public_vocabularies.preview_button}}</button>

    </div>
  </div>
</template>

<script>
import lang from "./lang";

export default {
  name: "MyVocabulary",
  mixins: [lang],
  props: ['id'],
  computed:{
    current()
    {
      return this.store.data.my_vocabularies.filter(item => item.id == this.id)[0];
    }
  },
  methods:{
    editVocabulary()
    {
      this.store.ui.views.vocabularies.currentId = this.current.id;
      this.store.ui.views.vocabularies.add_form = false;
      this.store.ui.views.vocabularies.edit_form = true;
      document.location.href = "#editBox";
    },
    viewVocabulary()
    {
      this.$router.push({path: '/vocabularies/my/'+this.id});
    }
  }
}
</script>

<style scoped>


.corner-ribbon{
  width: 200px;
  background: #e43;
  position: absolute;
  top: 25px;
  left: -50px;
  text-align: center;
  line-height: 50px;
  letter-spacing: 1px;
  color: #f0f0f0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

/* Custom styles */

.corner-ribbon.sticky{
  position: fixed;
}

.corner-ribbon.shadow{
  box-shadow: 0 0 3px rgba(0,0,0,.3);
}

/* Different positions */

.corner-ribbon.top-left{
  top: 25px;
  left: -50px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.corner-ribbon.top-right{
  top: 25px;
  right: -50px;
  left: auto;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.corner-ribbon.bottom-left{
  top: auto;
  bottom: 25px;
  left: -50px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.corner-ribbon.bottom-right{
  top: auto;
  right: -50px;
  bottom: 25px;
  left: auto;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

/* Colors */


.item {
  @apply
  w-80 rounded-md px-5 pt-2  pb-5 px-3 sm:px-5 relative m-2 flex-auto shadow-md

}
h2
{
  @apply
  text-2xl font-medium leading-none mt-3 text-center
}
</style>
