<template>
  <div class="intro-y flex items-center mt-8">
    <h2 class="text-lg font-medium mr-auto">{{currentPage.title}}</h2>
  </div>
  {{currentPage.description}}
  <!-- BEGIN: Pricing Layout -->
  <div class="intro-y box flex flex-col lg:flex-row mt-5">
    <div class="intro-y flex-1 px-5 py-16">
      <CreditCardIcon
        class="block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto"
      />
      <div class="text-xl font-medium text-center mt-10">{{currentPage.month.title}}</div>
      <div
        class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2 h-48"
      >
        {{currentPage.month.description}}
      </div>
      <div class="flex justify-center">
        <div class="relative text-5xl font-semibold mt-8 mx-auto">
            <span class="absolute text-2xl top-0 left-0 text-gray-600 -ml-4"
            >$</span
            >
          1.99
        </div>
      </div>
      <div class = "text-xs flex justify-center text-gray-600"  >+0.5$ {{currentPage.paypal_tax}}</div>
      <button
        type="button"
        class="btn btn-rounded-primary py-3 px-4 block mx-auto mt-8"
        @click = "pay('month')"
      >
        {{currentPage.pay_button}}
      </button>
    </div>
    <div
      class="intro-y border-b border-t lg:border-b-0 lg:border-t-0 flex-1 p-5 lg:border-l lg:border-r border-gray-200 dark:border-dark-5 py-16"
    >
      <BriefcaseIcon
        class="block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto"
      />
      <div class="text-xl font-medium text-center mt-10">{{currentPage.year.title}}</div>

      <div
        class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2 h-48"
      >
        {{currentPage.year.description}}
      </div>
      <div class="flex justify-center">
        <div class="relative text-5xl font-semibold mt-8 mx-auto">
            <span class="absolute text-2xl top-0 left-0 text-gray-600 -ml-4"
            >$</span
            >
          9.99
        </div>
      </div>
      <div class = "text-xs flex justify-center text-gray-600"  >+0.5$ {{currentPage.paypal_tax}}</div>
      <button
        type="button"
        class="btn btn-rounded-primary py-3 px-4 block mx-auto mt-8"
        @click = "pay('year')"
      >
        {{currentPage.pay_button}}
      </button>
    </div>
    <div class="intro-y flex-1 px-5 py-16">
      <ShoppingBagIcon
        class="block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto"
      />
      <div class="text-xl font-medium text-center mt-10">{{currentPage.donate.title}}</div>

      <div
        class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2 h-48"
      >
        {{currentPage.donate.description}}
      </div>

      <div class="flex justify-center">
        <div class="relative text-5xl font-semibold mt-8 mx-auto">
            <span class="absolute text-2xl top-0 left-0 text-gray-600 -ml-4"
            >$</span
            >
          <input type = "text"  class = "form-control w-24 h-12 text-5xl font-semibold"/>
        </div>
      </div>

      <button
        type="button"
        class="btn btn-rounded-primary py-3 px-4 block mx-auto mt-12"
        @click = "pay('donate')"
      >
        {{currentPage.pay_button}}
      </button>
    </div>


    <div class="text-center">
      <!-- BEGIN: Notification Content -->
      <div id="success-notification-content" class="toastify-content hidden flex">
        <CheckCircleIcon class="text-theme-9" />
        <div class="ml-4 mr-4">
          <div class="font-medium">Большое спасибо!</div>
          <div class="text-gray-600 mt-1">
            Все платежи обрабатываются вручную, необходимо какое-то время.
          </div>
        </div>
      </div>
      <!-- END: Notification Content -->
    </div>
  </div>


</template>

<script>

import lang from "@/views/pay/lang";
import Toastify from "toastify-js";
import Api from "@/api.js";

const api = new Api();

export default {
  name: "Pay",
  mixins: [lang],
  methods: {
    async pay(type) {

      var result = await api.post('pay',
        {
          data: {
            amount: this.amount,
            type: type
          },
          auth: true
        });

      if (result.status == '1')
      {
        window.open(result.url, '_blank');

      }
    }
  }

}
</script>
