import translate from "@/translate.js";
import store from "@/store";

    const lang  =
    {
        computed:
        {
            currentPage()
            {
              return translate[this.store.options.user.lang].layout.pay;
            }
        }
    }

    export default lang;
