<template>


  <div class="w-full grid-flow-row shadow stats" id = "result">
    <div class="flex flex-wrap w-full">

    <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y m-2 flex-auto">
      <div class="report-box">
        <div class="box p-5">
          <div class="flex">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <div class="text-3xl font-medium leading-8 mt-6">
            <div :class = "stat_color('max_sec', 1)"> {{stat_icon('max_sec', 1)}} {{store.finish.result.stat.max_sec}} {{currentPage.sec}} </div>
          </div>
          <div class="text-base text-gray-600 mt-1">{{currentPage.finish.max_time_label}}</div>
          <div v-if = "store.finish.result.stat_last" class="stat-desc">{{currentPage.finish.last_time_is}} {{store.finish.result.stat_last.max_sec}} {{currentPage.sec}}</div>
        </div>
      </div>
    </div>

    <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y m-2 flex-auto">
      <div class="report-box">
        <div class="box p-5">
          <div class="flex">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <div class="text-3xl font-medium leading-8 mt-6">
            <div :class = "stat_color('min_sec', 1)"> {{stat_icon('min_sec', 1)}} {{store.finish.result.stat.min_sec}} {{currentPage.sec}} </div>          </div>
          <div class="text-base text-gray-600 mt-1">{{currentPage.finish.min_time_label}}</div>
          <div v-if = "store.finish.result.stat_last" class="stat-desc">{{currentPage.finish.last_time_is}} {{store.finish.result.stat_last.min_sec}} {{currentPage.sec}}</div>
        </div>
      </div>
    </div>


    <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y m-2 flex-auto">
      <div class="report-box">
        <div class="box p-5">
          <div class="flex">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <div class="text-3xl font-medium leading-8 mt-6">
            <div :class = "stat_color('med_sec', 1)"> {{stat_icon('med_sec', 1)}} {{store.finish.result.stat.med_sec}} {{currentPage.sec}} </div>          </div>
          <div class="text-base text-gray-600 mt-1">{{currentPage.finish.mid_time_label}}</div>
          <div v-if = "store.finish.result.stat_last" class="stat-desc">{{currentPage.finish.last_time_is}} {{store.finish.result.stat_last.med_sec}} {{currentPage.sec}}</div>
        </div>
      </div>
    </div>


    <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y m-2 flex-auto">
      <div class="report-box">
        <div class="box p-5">
          <div class="flex">
           <svg   class="h-20 w-20" id="promo-promotion-discount-deal-percent-ribbon" style="enable-background:new 0 0 15.118 15.107;" version="1.1" viewBox="0 0 15.118 15.107" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M14.059,5.436V3.245l-2.204-1.102L9.712,0L7.559,0.538L5.406,0L3.263,2.143L1.059,3.245v2.191L0,7.554l1.059,2.118v2.191  l2.204,1.102l2.143,2.143l2.153-0.538l2.153,0.538l2.143-2.143l2.204-1.102V9.672l1.059-2.118L14.059,5.436z M13.059,9.436v1.809  l-1.724,0.862L9.406,14l-1.847-0.462L5.712,14l-1.8-1.8l-1.854-0.956V9.436L1.118,7.554l0.941-1.882V3.863l1.724-0.862l1.93-1.894  l1.847,0.462l1.847-0.462l1.8,1.8l1.854,0.956v1.809L14,7.554L13.059,9.436z"/><rect height="1" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.1273 7.5575)" width="8.485" x="3.316" y="7.054"/><path d="M5.559,7.054c0.827,0,1.5-0.673,1.5-1.5s-0.673-1.5-1.5-1.5s-1.5,0.673-1.5,1.5S4.732,7.054,5.559,7.054z M5.559,5.054  c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5s-0.5-0.224-0.5-0.5S5.283,5.054,5.559,5.054z"/><path d="M9.559,8.054c-0.827,0-1.5,0.673-1.5,1.5s0.673,1.5,1.5,1.5s1.5-0.673,1.5-1.5S10.386,8.054,9.559,8.054z M9.559,10.054  c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5S9.835,10.054,9.559,10.054z"/></svg>
          </div>
          <div class="text-3xl font-medium leading-8 mt-6">
            <div :class = "stat_color('present')">  {{stat_icon('present')}} {{store.finish.result.stat.present}}%</div>
          </div>
          <div class="text-base text-gray-600 mt-1">{{currentPage.finish.present_label}}</div>
          <div v-if = "store.finish.result.stat_last" class="stat-desc">{{currentPage.finish.last_time_is}} {{store.finish.result.stat_last.present}} %</div>
        </div>
      </div>
    </div>

    </div>
    <div  v-if = "store.finish.result.stat.bad_words > 0" class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y mt-5">
      <div class="report-box">
        <div class="box p-5">
          <div class="flex">

          </div>
          <div class="text-3xl font-medium leading-8 mt-6">
            {{currentPage.finish.this_words_need_try_label}}:
          </div>
          <div class="text-base text-gray-600 mt-1">
            <i v-for = "(word, ii) in bad_words_list">
              {{word.note1}}{{ (ii != bad_words_list.length - 1) ? ', ' : '' }}
            </i>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lang from "../mixins.js";
import {computed} from "@vue/reactivity";

export default {
  name: "stat",
  mixins: [lang],
  methods:{
    stat_color(key, invert)
    {
      var res = this.compare(key, invert);


      switch (res)
      {
        case '>':
          return 'stat-value text-error';
        case '<':
          return 'stat-value text-success';
        case '=':
          return 'stat-value';
      }
      return 'stat-value';

    },
    stat_icon(key, invert)
    {
      var res = this.compare(key, invert);

      switch (res)
      {
        case '<':
          return '↗︎';
        case '>':
          return '↘︎';
        case '=':
          return '';
      }
    },
    compare(key, invert)
    {

      if (typeof(this.store.finish.result.stat_last[key] != null) && (typeof(this.store.finish.result.stat_last[key])))
      {
        if (this.store.finish.result.stat[key] > this.store.finish.result.stat_last[key])
        {
          return ((invert !=null) ? '>' : '<');

        }
        if (this.store.finish.result.stat[key] < this.store.finish.result.stat_last[key])
        {
          return ((invert !=null) ? '<' : '>');
        }
        else
        {
          return '='
        }

      }
    },
  },
  computed:{
    bad_words_list()
    {
      return  this.store.data.cards.filter((item) => {
        return  (item.result == false)
      });
    }
  }
}
</script>

<style scoped>

</style>
