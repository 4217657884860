<template>
  <div>
    <div v-if = "this.store.data.cards && this.store.data.vocabulary">
    <div>
      <div class="intro-y box col-span-12 2xl:col-span-6">
        <div
          class="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200 dark:border-dark-5">
          <h2 class="font-medium text-base mr-auto">{{store.data.vocabulary.title}}</h2>
          <div>
            <div class="text-lg font-medium" v-if = "store.data.vocabulary.image">
              <img :src = "store.data.vocabulary.image" >
            </div>
          </div>
        </div>
        <div class="p-5">
          <div class="col-span-12" v-html = "store.data.vocabulary.description">
          </div>
        </div>
        <div class="intro-y flex text-xs sm:text-sm flex-col sm:flex-row items-center mt-5 pt-5 border-t border-gray-200 dark:border-dark-5">
          <div class="ml-3 mr-auto mb-2 text-gray-600">{{currentPage.cards.author_title}} <div class="text-gray-800 font-bold">{{store.data.vocabulary.author}}</div></div>
        </div>
      </div>
    </div>
      <div class="intro-y box col-span-12 2xl:col-span-6 mt-5">
          <div v-if = "store.data.cards">
            <div class = "bg-gray-200">
              <div class="flex justify-end">
                <div class = "mt-2 mb-2">
                  <input
                    type="text"
                    class="form-control w-56 box pr-10 placeholder-theme-13"
                    v-bind:placeholder="currentPage.cards.table.search.placeholder"
                    v-model = "store.data.searchWord"
                  />
                  <SearchIcon
                    class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
                  />
                </div>
              </div>
            </div>
          <div class="intro-y box col-span-12 2xl:col-span-6">
            <div class = "h-80">
              <div class = "w-full overflow-auto h-full">
          <table class="mt-0 border-collapse w-full" style = "overflow: auto;">
          <thead style = "position: -webkit-sticky; position: sticky; top: 0px; z-index:100">
          <tr>
          <th class="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
            <input  class="form-check-input" type="checkbox" value="" @change="setAllChecked" />
          </th>
          <th class="th_item"  @click = "sortBy('note1')">{{currentPage.cards.table.note1.label}} <span v-if = "store.data.sortBy == 'note1'">↓</span></th>
          <th class="th_item"  @click = "sortBy('note2')">{{currentPage.cards.table.note2.label}}  <span v-if = "store.data.sortBy == 'note2'">↓</span></th>
          <th class="th_item"  @click = "sortBy('note3')">{{currentPage.cards.table.note3.label}}  <span v-if = "store.data.sortBy == 'note3'">↓</span></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for = "cardItem in store.data.cards"  class = "tr">
               <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
                 <input  class="form-check-input" type="checkbox" v-model = "cardItem.checked"/>
               </td>
              <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase"> {{currentPage.cards.table.note1.label}} </span>
                <span  v-if = "wordFound(cardItem, 'note1')" v-html = "highlight(cardItem.note1)"></span>
                <span v-else>{{cardItem.note1}}</span>
              </td>
              <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase"> {{currentPage.cards.table.note2.label}} </span>
                 <span v-if = "wordFound(cardItem, 'note2')" v-html = "highlight(cardItem.note2)"></span>
                <span v-else>{{cardItem.note2}}</span>
              </td>
              <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b text-center block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-2 py-1 text-xs font-bold uppercase">{{currentPage.cards.table.note3.label}}</span>
               <span v-if = "wordFound(cardItem, 'note3')" v-html = "highlight(cardItem.note3)"></span>
                <span v-else>{{cardItem.note3}}</span>
              </td>
            </tr>
          </tbody>
          </table>
          </div>
            </div>
            </div>
          </div>
          <div v-else>
            Loading...
          </div>
      </div>
    </div>
    <div class="flex row w-full">
      <div class = "grow m-2 ml-0 mr-0 ">
        <TomSelect  :value = "store.options.user.default_category" v-model = "this.store.data.importToId">
          <option v-for = "item in store.data.my_vocabularies" :value = "item.id">{{item.title}}</option>
        </TomSelect>
      </div>
      <div class = "w-fit m-2">
      <button  v-if = "isChecked"   type="button" class="btn btn-primary w-24" @click = "importCards">{{currentPage.cards.table.import_button}}</button>
      <Tippy v-else tag="a" href="javascript:;" class="tooltip btn btn-second" v-bind:content="currentPage.cards.table.import_button_disabled_tooltip">{{currentPage.cards.table.import_button}}</Tippy>
      </div>
    </div>

    <div id="success-notification-content" class="toastify-content hidden flex">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">{{currentPage.edit_vocabulary.success_saved.label}}</div>
        <div class="text-gray-600 mt-1">
          {{currentPage.cards.table.import_succes_description}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import Api from "@/api.js";
import lang from "@/views/vocabularies/lang";

import async from "async";
import Toastify from "toastify-js";
import table from "@/mixins/table";

const api = new Api();
export default {
  name: "PublicCards",
  mixins: [lang, table],
  props: ['id'],
  setup()
  {
    const editorData = ref('');

    return {
      editorData
    }

  },
  data()
  {
    return {
      new_vocabulary: false
    }
  },
  methods:{
    async importCards() {
      var ids = [];
      this.store.data.cards.forEach(item=> {
        if (item.checked)
        {
          ids.push(item.id);
        }
      });


      var result =  await api.post('card/import_to_vocabulary/'+this.store.data.importToId,
        {
          data: {ids: ids},
          auth: true
        });

     if (result.status == '1')
     {

       Toastify({
         node: cash("#success-notification-content")
           .clone()
           .removeClass("hidden")[0],
         duration: -1,
         newWindow: true,
         close: true,
         gravity: "top",
         position: "right",
         stopOnFocus: true
       }).showToast();
     }
    },
    wordFound(item, index)
    {
      return !!(this.store.data.searchWord &&  item[index] && typeof(item[index]) == 'string' && item[index].toLowerCase().includes(this.searchWord.trim().toLowerCase()))
    }
  },
  async mounted()
  {
    this.store.data.cards         = await api.get('card/read_by_vocabulary/'+this.$route.params.id, {auth: true});
    this.store.data.vocabulary    = await api.get('vocabulary/read/'+this.$route.params.id, {auth: true});
    this.store.data.cards         = await api.get('card/read_by_vocabulary/'+this.$route.params.id, {auth: true});
    this.store.data.cards.forEach(item=> {
      item.checked = false;
    });
    this.store.data.hidden_cards  =  this.store.data.cards;
  },
}
</script>

<style scoped>
.th_item {
  @apply
  p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell cursor-pointer
}

</style>
