// define a mixin object
import translate from "@/translate";
import Toastify from "toastify-js";

const table  = {
  watch:{
    searchWord(value)
    {
      if (value && value.length < 2)
      {
        this.store.data.cards =  JSON.parse(JSON.stringify(this.store.data.hidden_cards));
      }
      else
      {
        var result = this.store.data.hidden_cards.filter((item)=> {
          return item.note1 && item.note1.trim().toLowerCase().includes(value) || item.note2 && item.note2.trim().toLowerCase().includes(value) || item.note3 && item.note3.trim().toLowerCase().includes(value);
        });
        this.store.data.cards =  JSON.parse(JSON.stringify(result));
      }
    }
  },
  computed:
    {
      searchWord()
      {
        return this.store.data.searchWord;
      },
      isChecked()
      {
        var sum  = this.store.data.cards.filter(item=> {
          return item.checked == true;
        });
        return sum.length > 0;
      },
      chekedIds()
      {
        var ids = [];
        this.store.data.cards.forEach(item=> {
          if (item.checked)
          {
            ids.push(item.id);
          }
        });

        return ids;
      },
      checkedItems()
      {
        var cards = this.store.data.cards.filter(item=> {
          return item.checked == true;
        });
        return cards;
      }
    },
  methods:
  {
    highlight(itemToHighlight)
    {
      if(!this.searchWord)
      {
        return itemToHighlight;
      }
      return itemToHighlight.replace(new RegExp(this.searchWord, "ig"), match => {
        return '<span class = "bg-theme-17 text-black"">' + this.searchWord + '</span>' + (match.replace(this.searchWord, ''));
      });
    },
    setAllChecked()
    {
      this.store.data.cards.forEach(item=> {
        item.checked = !item.checked;
      });
    },


    sortBy(arg)
    {
      this.store.data.cards.sort((a, b)=>{
        return a[arg] > b[arg];
      });
      this.store.data.sortBy = arg;
    },

    async importCards() {
      var ids = [];
      this.store.data.cards.forEach(item=> {
        if (item.checked)
        {
          ids.push(item.id);
        }
      });


      var result =  await api.post('card/import_to_vocabulary/'+this.store.data.importToId,
        {
          data: {ids: ids},
          auth: true
        });

      if (result.status == '1')
      {

        Toastify({
          node: cash("#success-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: -1,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();
      }
    }
  }
}


export default table;
