<template>
  <div class="flex flex-wrap w-full">
    <card v-for ="item in store.data.cards" v-model:id = "item.id" :key = "item.id"></card>
  </div>
</template>
<script>
import card from "./card.vue";


export default {
  name: "Game",
  mounted()
  {
    this.store.data.cardsIndex = 0;
    this.store.data.cards[0].play_status = 'started';
  },
  components: {
    card
  },

  methods: {
    showOptions()
    {
      this.store.options.showOptions =  !this.store.options.showOptions;

    },
    start()
    {

    },

  },
  computed:{
    currentIndex()
    {
      return this.store.data.cardsIndex;
    }
  },

  watch:
      {
        currentIndex(val)
        {
          if ((val)  == this.store.data.cards.length)
          {
            setTimeout(()=> {
             var audio = new Audio('/sounds/game_win.mp3');
             this.store.ui.page = 'finish';
              audio.play();
            }, 1000);


          }
          else
          {
            this.store.data.cards[val].play_status = 'started';
          }
        }

      }

}
</script>

<style>


.card-text svg
{
  position: absolute;
  width: 200px;
  height: 200px;
  transform: rotateY(-180deg) rotateZ(-90deg);
}

.countdown-number
{
  font-size:40px;
  color: green;
  font-family: "Roboto Medium";
  margin: auto;
  text-align:center

}
.circle
{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);

}



.card-text svg circle {
  stroke-dasharray: 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-width: 8px;
  stroke: green;
  fill: none;

}

@keyframes countdown {
  0% {
    stroke-dashoffset: 0;
    stroke: green;
  }
  50%
  {
    stroke: yellow;
  }
  100% {
    stroke-dashoffset: 91px;
    stroke: red;
  }
}

@keyframes countdown_number {
  0% {
    color: green;
  }
  50%
  {
    color: yellow;
  }
  100% {

    color: red;
  }
}




</style>
