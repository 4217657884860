<template>
      <div  class=" font-sans w-4/5 antialiased " v-html = "currentPage.annotation"></div>
</template>
<script>

import lang from "../mixins.js";

export default {
  name: "annotation",
  mixins: [lang]
}
</script>

<style scoped>

</style>
