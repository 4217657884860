<template>
  <router-view />
</template>
<script>
import store from './store'
import Api from "@/api";
const api = new Api();

export default {
  async mounted()
  {
    if (store.options.user.key !== '')
    {
      store.options.user =   await api.get('user', {auth: true});
    }
    else
    {
      if (document.location.hash !== '#!/login')
      {
        this.$router.push('/login');
      }
    }
  }
}
</script>
