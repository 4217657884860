<template>
    <div class = "container">
      <div class = "mx-4 sm:p-6 mt-5 box">
      <div class="modal-header"><h2 class="font-medium text-base mr-auto" >{{currentPage.options.title}}</h2></div>
      <div class="p-2">

        <div class="mt-2">
          <label>  <span>{{currentPage.options.language.label}}</span></label>
          <div>
            <TomSelect  v-model = "store.options.user.lang">
              <option v-for = "(item, key) in store.options.languages" :value = "item">{{currentPage.languages[item].title}}</option>
            </TomSelect>
          </div>
        </div>
        <div class="mt-2">
          <label>  <span>{{currentPage.options.default_vocabulary.label}}</span></label>
          <div>
            <TomSelect  v-model = "store.options.user.default_vocabulary">
              <option v-for = "item in store.data.my_vocabularies" :value = "item.id">{{item.title}}</option>
            </TomSelect>
          </div>
        </div>
        <div class="mt-2">
          <label>
            <span>{{currentPage.options.key.label}}</span>
          </label>
          <div >
            <b>
              {{ store.options.user.key }}
            </b>
          </div>
        </div>

        <div class="mt-2">
            <div>
              <label>
                <span>{{currentPage.options.end_date.label}}</span>
              </label>
            </div>
          <div class = "w-full flex">
            <div >
              <b>
                {{ store.options.user.end_date }}
              </b>
            </div>
            <div class = "ml-5"><button type="button" class="btn btn-primary w-32" @click = "redirectToPay">{{currentPage.options.extend_button}}</button></div>
          </div>
        </div>

        <div class="mt-2">
          <label>
            <span>{{currentPage.options.limitations.label}}</span>
          </label>
          <div >
            <b>
              1000 cards
            </b>
          </div>
        </div>



        <div class="modal-footer text-right"><button type="button" class="btn btn-primary w-32" @click="saveSettings" >{{currentPage.save_button}}</button></div>
      </div>


    </div>


  <div class = "mx-4 sm:p-6 box mt-5">
    <div class="modal-header"><h2 class="font-medium text-base mr-auto">{{currentPage.profile.title}}</h2></div>
    <div class="p-2">
      <div class="mt-2">
        <label><span>{{currentPage.profile.name.label}}</span></label>
        <div >
          <input id="regular-form-3" type="text" class="form-control" v-model ="store.options.user.name">
        </div>
      </div>
    </div>
    <div class="modal-footer text-right"><button type="button" class="btn btn-primary w-32" @click="saveSettings" >{{currentPage.save_button}}</button></div>
  </div>
    </div>
  <div class="text-center">
    <!-- BEGIN: Notification Content -->
    <div id="success-notification-content" class="toastify-content hidden flex">
      <CheckCircleIcon class="text-theme-9" />
      <div class="ml-4 mr-4">
        <div class="font-medium">{{currentPage.success_saved.label}}</div>
        <div class="text-gray-600 mt-1">
          {{currentPage.success_saved.description}}
        </div>
      </div>
    </div>
    <!-- END: Notification Content -->
  </div>
</template>

<script>

import lang from "@/views/settings/lang";
import Api from "@/api.js";
import Toastify from "toastify-js";

const api = new Api();

export default {
  name: "Main",
  mixins: [lang],
  async mounted() {
    this.store.data.my_vocabularies = await api.get('vocabulary/my', {auth: true});
  },
  methods:{
   async saveSettings()
    {

      var result =  await api.post('settings/update',
        {
          data: {
            lang: this.store.options.user.lang,
            default_vocabulary: this.store.options.user.default_vocabulary,
            name: this.store.options.user.name
          },
          auth: true
        });

      if (result.status == '1')
      {

        if (this.default_vocabulary == true)
        {
          //обновим еще пользователя

          this.store.options.user.default_category = result.id;
        }
        Toastify({
          node: cash("#success-notification-content")
            .clone()
            .removeClass("hidden")[0],
          duration: -1,
          newWindow: true,
          close: true,
          gravity: "top",
          position: "right",
          stopOnFocus: true
        }).showToast();

      }
    },
    redirectToPay()
    {
      this.$router.push({path: '/pay'});
    }

  }
}
</script>
